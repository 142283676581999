@import url('//at.alicdn.com/t/font_3158952_460e6amf7jp.css');
/* CSS Document */
//@font-face {
//    font-family: 'iconfont';
//    src: url('../font/iconfont.eot');
//    src: local('☺'), url('../font/iconfont.woff') format('woff'), url('../font/iconfont.ttf') format('truetype'), url('../font/iconfont.woff2') format('woff2'), url('../font/iconfont.eot') format('eot'), url('../font/iconfont.otf') format('otf');
//}
//SCSS全局变量
// 默认移动端设计稿宽度
$baseDevice: 750;
// 前端切图移动端默认正常显示尺寸，默认为设计稿的一半
$device: $baseDevice / 2;
// 默认html font-size
$baseFontSize: 100px;
// scss function
@function calc-percent($target, $context) {
    @return $target/$context * 100%;
}
@function px2rem($px, $base-font-size: $baseDevice / $device * $baseFontSize) {
    @if (unitless($px)) {
        @warn "Assuming #{$px} to be in pixels, attempting to convert it into pixels for you";
        @return px2rem($px + 0px); // That may fail.
    } @else if (unit($px)==rem) {
        @return $px;
    }
    @return ($px / $base-font-size) * 1rem;
}
//透明
@mixin touming($o: 70) {
    opacity: $o/100;
    -webkit-opacity: $o/100;
    filter: alpha(opacity=$o);
}
@mixin transition($time: .2s) {
    -webkit-transition: $time ease all;
    -moz-transition: $time ease all;
    transition: $time ease all;
}
@mixin articleReset($fontsize: 14px, $lineHieght: 30px) {
    font-size: $fontsize;
    p {
        margin-bottom: $lineHieght/2;
    }
    img {
        max-width: 100%;
        height: auto !important;
    }
    ul {
        list-style: disc;
        li {
            list-style: disc;
        }
    }
    ol {
        list-style: decimal;
        li {
            list-style: decimal;
        }
    }
}
// 多行省略
@mixin multiEllipsis($line: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: $line;
    -webkit-box-orient: vertical;
}
/* reset.css V1.6  Start*/
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
dl,
dt,
dd,
ul,
ol,
li,
form,
button,
input,
textarea,
th,
td {
    font-weight: normal;
    margin: 0;
    padding: 0;
}
body,
button,
input,
select,
textarea,
h1,
h2,
h3,
h4,
h5,
h6,
i {
    font: normal 12px Helvetica Neue, Arial, Microsoft YaHei, PingFang SC, Source Han Sans SC, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}
ul,
ol,
li {
    list-style: none;
}
a {
    text-decoration: none;
    color: #333;
}
a:hover {
    text-decoration: none;
}
img {
    vertical-align: top;
    border: 0;
}
button,
input,
textarea {
    font-size: 100%;
    vertical-align: middle;
    outline: none;
    border-radius: 0;
    -webkit-appearance: none;
}
table {
    border-spacing: 0;
    border-collapse: collapse;
}
div,
a {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}
html {
    font-size: $baseFontSize;
}
@media screen and (min-width: 320px) {
    html {
        font-size: (320/$device) * $baseFontSize;
    }
}
@media screen and (min-width: 360px) {
    html {
        font-size: (360/$device) * $baseFontSize;
    }
}
@media screen and (min-width: 375px) {
    html {
        font-size: (375/$device) * $baseFontSize;
    }
}
@media screen and (min-width: 480px) {
    html {
        font-size: (480/$device) * $baseFontSize;
    }
}
@media screen and (min-width: 640px) {
    html {
        font-size: (640/$device) * $baseFontSize;
    }
}
@media screen and (min-width: 750px) {
    html {
        font-size: (750/$device) * $baseFontSize;
    }
}
.clearfloat:after {
    font-size: 0;
    display: block;
    visibility: hidden;
    clear: both;
    height: 0;
    content: ' ';
}
.clearfloat {
    *zoom: 1;
}
.clearboth {
    clear: both;
}
.wp1200 {
    width: 1200px;
    margin: 0 auto;
    @media (max-width: 1260px) {
        width: auto;
        margin: 0 px2rem(30px);
    }
}

.wow {
    visibility: hidden;
}

.checkBrowser {
    font-size: 14px;
    line-height: 40px;
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 50%;
    width: 700px;
    height: 40px;
    margin-left: -360px;
    padding: 0 10px;
    color: #fff;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    background-color: #e6212a;
    a {
        font-size: 16px;
        line-height: 20px;
        position: absolute;
        top: 10px;
        right: 5px;
        display: block;
        width: 20px;
        height: 20px;
        cursor: pointer;
        text-align: center;
        color: #333;
        border-radius: 50%;
        background-color: #fff;
    }
}
/* reset.css V1.6  End */
// page结构以及CSS样式
// *******************
// ********************
// ********************
// HTML结构
// <div class="paged">
//  <a href="#">首页</a>
//  <a class="prev">上一页</a>
//  <span class="current">1</span>
//  <a href="#">2</a>
//  <a class="next" href="#">下一页</a>
//  <a href="#">尾页</a>
// </div>
// ********************
// ********************
// ********************
// CSS样式
.paged {
    padding-top: px2rem(50px);
    text-align: center;
}
.paged a,
.paged .p_info,
.paged .current {
    display: inline-block;
    margin: 0 3px 2px;
    padding: 0 15px;
    font-size: 13px;
    text-align: center;
    border: 1px solid #c8cace;
    //background: #f3f3f3;
    color: #8b8b8b;
    border-radius: 4px;
    height: 40px;
    line-height: 40px;
    @media (max-width: 750px) {
        margin: 0 2px 2px;
        padding: 0 10px;
        font-size: 12px;
        height: 30px;
        line-height: 30px;
    }
}
.paged .current,
.paged a:hover {
    border: 1px solid #1f78fd;
    background: #1f78fd;
    color: #fff;
}
// 图片放大组件样式
.article-swiper {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .8);
    touch-action: none;
    z-index: 8888;
    .swiper-zoom-container {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        height: 100%;
    }
    .swiper-pagination {
        bottom: 1em;
        color: #fff;
    }
    .swiper-slide {
        img {
            max-width: 95%;
            max-height: calc(100% - 6em);
            vertical-align: middle;
        }
        p {
            position: absolute;
            top: 1em;
            left: 0;
            width: 100%;
            text-align: center;
            font-size: 16px;
            color: #fff;
            z-index: 22;
        }
    }
    .swiper-close-icon {
        position: absolute;
        top: px2rem(100px);
        right: px2rem(60px);
        z-index: 9999;
        .iconfont {
            color: #fff;
            font-size: px2rem(50px);
        }
        width: 40px;
        height: 40px;
        cursor: pointer;
        &::after {
            width: 100%;
            position: absolute;
            height: 3px;
            background: #fff;
            content: "";
            top: 0;
            left: 0;
            transform: rotate(-45deg);
        }
        &::before {
            width: 100%;
            position: absolute;
            height: 3px;
            background: #fff;
            content: "";
            top: 0;
            right: 0;
            transform: rotate(45deg);
        }
    }
}
.header {
    background-color: #1f232b;
    height: px2rem(80px);
    @media screen and (max-width: 1024px) {
        height: px2rem(100px);
    }
    &-wrapper {
        background-color: #1f232b;
        position: fixed;
        left: 0;
        width: 100%;
        top: 0;
        z-index: 31;
    }
    .wp1200 {
        height: px2rem(80px);
        @media screen and (max-width: 1024px) {
            height: px2rem(100px);
        }
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .logo {
        margin-right: 50px;
        img {
            height: 32px;
        }
    }
    &-btn-box {
        .btn {
            display: inline-block;
            vertical-align: top;
            height: 32px;
            line-height: 32px;
            padding: 0 18px;
            border: 1px solid rgba(#fff, .25);
            border-radius: 17px;
            margin-left: px2rem(10px);
            cursor: pointer;
            font-size: 14px;
            color: #fff;
            transition: all .2s;
            &:hover {
                background: #1f78fd;
            }
        }
        .register {
            background: rgba(#a6a6a6, .25);
        }
    }
    .header-mobile-menu {
        display: none;
        vertical-align: top;
        line-height: 32px;
        color: #fff;
        font-size: 28px;
        margin-left: px2rem(20px);
        cursor: pointer;
    }
    @media (max-width: 1200px) {
        .header-mobile-menu {
            display: inline-block;
        }
    }
    @media (max-width: 750px) {
        .wp1200 {
            height: 50px;
        }
        .logo {
            margin-right: 0;
            img {
                height: 24px;
            }
        }
        &-btn-box {
            .btn {
                height: 22px;
                line-height: 22px;
                padding: 0 10px;
                border-radius: 11px;
                font-size: 10px;
            }
        }
        .header-mobile-menu {
            line-height: 22px;
            font-size: 18px;
        }
    }
}
.nav-mobile {
    position: absolute;
    width: px2rem(360px);
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 103;
    background: #fff;
    box-sizing: border-box;
    overflow: hidden;
    transform: translateX(100%);
    transition: transform .4s ease;
    &.anim {
        transform: translateX(0);
    }
    &-close {
        height: px2rem(90px);
        display: flex;
        justify-content: flex-end;
        padding: 0 px2rem(20px);
        align-items: center;
        font-size: px2rem(30px);
        color: #9a9a9a;
        border-bottom: 1px solid #ededed;
    }
    &-con {
        display: none;
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        z-index: 9902;
        box-sizing: border-box;
        overflow: hidden;
        .bg {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, .4);
            z-index: 102;
        }
    }
    &-top {
        width: 100%;
        height: px2rem(110px);
        line-height: px2rem(110px);
        box-sizing: border-box;
        padding: 0 px2rem(50px);
        background-color: #393939;
        font-size: px2rem(30px);
        color: #fff;
        z-index: 103;
        position: absolute;
        top: 0;
        left: 0;
    }
    &-search {
        position: absolute;
        left: 0;
        top: px2rem(110px);
        background-color: #f5f5f5;
        padding: px2rem(18px) px2rem(40px);
        display: flex;
        z-index: 104;
        width: 100%;
        box-sizing: border-box;
        input[type="text"] {
            flex: 1;
            height: px2rem(50px);
            line-height: px2rem(50px);
            appearance: none;
            border: none;
            font-size: px2rem(26px);
            background: none;
        }
        button {
            width: px2rem(80px);
            height: px2rem(50px);
            border: none;
            appearance: none;
            //font-family: iconfont;
            display: block;
            background: none;
            font-size: px2rem(40px);
            color: #555;
            //&:before {
            //    content: '\e627';
            //}
        }
    }
    &-wp {
        position: relative;
    }
    &-title {
        position: relative;
        padding: 0 px2rem(40px);
        border-bottom: 1px solid #ededed;
        > a {
            position: relative;
            display: block;
            line-height: px2rem(84px);
            font-size: px2rem(28px);
            color: #333;
            z-index: 2;
            backface-visibility: hidden;
            width: 100%;
        }
    }
    &-child-btn {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        text-align: right;
        line-height: px2rem(84px);
        font-size: px2rem(30px);
        font-weight: bold;
        z-index: 1;
        color: #848484;
        span {
            display: inline-block;
            margin-right: px2rem(40px);
        }
        i {
            position: absolute;
            display: block;
            width: px2rem(40px);
            height: 100%;
            top: 0;
            right: px2rem(10px);
            color: #888;
            line-height: px2rem(84px);
            text-align: center;
            font-size: px2rem(30px);
            //transform: rotate(180deg);
            transition: transform .2s ease;
            font-family: 'iconfont';
            &:before {
                content: '\e632';
            }
        }
        &.open {
            i {
                transform: rotate(180deg);
            }
        }
        + a {
            display: inline-block;
            width: auto;
        }
    }
    .sub {
        //padding: 0 px2rem(40px);
        display: none;
        li {
            border-bottom: 1px solid #ededed;
            padding-left: px2rem(30px);
            position: relative;
            height: px2rem(84px);
            overflow: hidden;
            a {
                display: block;
                line-height: px2rem(84px);
                font-size: px2rem(28px);
                height: px2rem(84px);
                color: #999;
                overflow: hidden;
            }
        }
        .pl-45 {
            padding-left: px2rem(45px);
        }
        .pl-55 {
            padding-left: px2rem(55px);
        }
    }
}
.nav {
    //flex: 1;
    min-width: 0;
    font-size: 0;
    li {
        position: relative;
        z-index: 20;
        display: inline-block;
        vertical-align: top;
        > a {
            display: flex;
            align-items: center;
            font-size: 16px;
            height: 80px;
            color: #fff;
            padding: 0 28px;
            transition: all .2s;
        }
        &.on,
        &:hover {
            > a {
                background: #3c4454;
            }
        }
        .sub {
            position: absolute;
            left: -350px;
            top: 80px;
            background-color: #fff;
            box-shadow: 0 0 10px rgba(#000, .1);
            // width: 1014px;
            width: 780px;
            display: none;
        }
        .wrapper {
            display: flex;
            width: 100%;
            box-sizing: border-box;
            padding: 0 20px;
            height: 120px;
            align-items: center;
            justify-content: space-around;
        }
        .item {
            display: flex;
            align-items: center;
            img {
                display: block;
                width: 48px;
                height: 48px;
                margin-right: 20px;
            }
            .t {
                font-size: 16px;
                font-weight: bold;
                color: #333;
                transition: color .2s;
                margin-bottom: 5px;
            }
            .p {
                font-size: 12px;
                color: #999;
            }
            &:hover {
                .t {
                    color: #1f78fd;
                }
            }
        }
    }
    @media (max-width: 1200px) {
        display: none;
    }
}
.friend-con {
    background: #08213a;
    padding: px2rem(40px) 0 px2rem(20px);
    font-size: 14px;
    line-height: 28px;
    color: #fff;
    @media (max-width: 800px) {
        display: none;
    }
    .wp1200 {
        display: flex;
    }
    .p {
        flex: 1;
        overflow: hidden;
        a {
            display: inline-block;
            vertical-align: top;
            color: rgba(#fff, .5);
            margin-right: px2rem(24px);
            &:hover {
                color: #fff;
            }
        }
    }
}
.footer {
    background-color: #08213a;
    border-top: 1px solid rgba(#fff, .1);
    padding: px2rem(70px) 0 px2rem(60px);
    .wp1200 {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }
    .footer-logo {
        margin-bottom: 10px;
    }
    .phone {
        font-size: px2rem(40px);
        font-family: Arial;
        font-weight: bold;
        color: #fff;
    }
    .p {
        font-size: 16px;
        line-height: 28px;
        color: rgba(#fff, .5);
        p {
            margin-bottom: 8px;
        }
        a {
            color: rgba(#fff, .5);
            &:hover {
                color: #fff;
            }
        }
        img {
            margin: 0 5px;
        }
    }
    .link {
        color: #fff;
        font-size: 14px;
        line-height: 28px;
        a {
            display: inline-block;
            vertical-align: top;
            color: #fff;
            margin: 0 10px;
            &:first-child {
                margin-left: 0;
            }
            &:hover {
                text-decoration: underline;
            }
        }
    }
    .ewm {
        border-radius: 15px;
        padding: 15px;
        background: #091d31;
        text-align: center;
        color: rgba(#fff, .5);
        font-size: 14px;
        width: 111px;
        img {
            width: 111px;
            height: 111px;
            margin-bottom: 8px;
        }
    }
    @media (max-width: 750px) {
        text-align: center;
        .wp1200 {
            display: block;
        }
        .ewm {
            margin: 0 auto;
        }
        .footer-logo {
            text-align: center;
            img {
                height: 20px;
            }
        }
        .p {
            font-size: 14px;
            line-height: 26px;
        }
        .link {
            display: none;
        }
    }
}
.footer-icon-box {
    box-sizing: border-box;
    border: 1px solid #2f455c;
    width: 100%;
    display: flex;
    margin-bottom: px2rem(20px);
    .item {
        flex: 1;
        overflow: hidden;
        display: flex;
        box-sizing: border-box;
        align-items: center;
        justify-content: space-between;
        padding-left: 15px;
        height: 100px;
        padding-right: 5px;
        + .item {
            border-left: 1px solid #2f455c;
        }
    }
    .img {
        width: 64px;
        height: 64px;
        margin-right: 5px;
        img {
            display: block;
            width: 100%;
            height: 100%;
        }
    }
    .info {
        flex: 1;
        overflow: hidden;
        font-size: 14px;
        line-height: 20px;
        color: rgba(#fff, .8);
    }
}
.go-top {
    position: fixed;
    right: 0;
    bottom: px2rem(200px);
    z-index: 54;
    border: 1px solid #ededed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #f1f1f1;
    width: 70px;
    height: 70px;
    border-radius: 4px;
    font-size: 12px;
    transition: all .2s;
    cursor: pointer;
    i {
        font-size: 26px;
        margin-bottom: 5px;
    }
    &:hover {
        background: #1f78fd;
        color: #fff;
        border-color: #1f78fd;
    }
    @media (max-width: 750px) {
        width: 30px;
        height: 30px;
        i {
            font-size: 14px;
            margin-bottom: 0;
        }
        p {
            display: none;
        }
    }
}