.lab-banner {
    position: relative;
    overflow: hidden;

    .bg {
        height: 580px;

        @media screen and (max-width: 1024px) {
            height: px2rem(630px);
        }

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;

            &:nth-of-type(2) {
                display: none;
            }

            @media screen and (max-width: 1024px) {
                &:nth-of-type(1) {
                    display: none;
                }

                &:nth-of-type(2) {
                    display: inline-block;
                }
            }
        }
    }

    .word {
        position: absolute;
        backface-visibility: hidden;
        left: 50%;
        margin-left: -600px;
        top: 140px;
        z-index: 2;
        width: 530px;
        color: #fff;

        @media screen and (max-width: 1260px) {
            margin-left: 0;
            width: 100%;
            top: px2rem(80px);
            left: 0;
            padding-left: px2rem(70px);
        }

        .big {
            font-size: 55px;
            font-weight: bold;

            @media screen and (max-width: 1024px) {
                font-size: px2rem(55px);
                line-height: 1.1;
                margin-bottom: px2rem(5px);
            }
        }

        .small {
            font-size: 32px;

            @media screen and (max-width: 1024px) {
                font-size: px2rem(32px);
                line-height: 1.5;
                font-weight: bold;
            }
        }
    }
}

.lab-web3 {
    background-size: cover;
    background-repeat: no-repeat;
    height: 1030px;
    padding-top: px2rem(150px);
    box-sizing: border-box;
    @media screen and (max-width: 1024px) {
        height: auto;
        padding-bottom: px2rem(150px);
    }
    &-content {
        display: flex;
        align-items: center;
        @media screen and (max-width: 1024px) {
            flex-flow: column;
        }
    }

    .left {
        width: 0;
        flex: 1;
        padding-right: 80px;
        @media screen and (max-width: 1024px) {
            padding-right: 0;
            width: 100%;
            margin-bottom: px2rem(150px);
        }
        .title {
            font-size: 50px;
            font-weight: bold;
            color: #fff;
            margin-bottom: px2rem(30px);
            @media screen and (max-width: 1024px) {
                font-size: px2rem(64px);
                margin-bottom: px2rem(50px);
            }
        }

        .info {
            font-size: 14px;
            color: rgba(#fff, .55);
            line-height: 28px;
            text-align: justify;
            margin-bottom: px2rem(35px);
        }
        .img-box {
            img {
                width: 100%;
            }
        }
    }
    .right {
        flex: 1;
        .img-box {
            img {
                width: 100%;
            }
        }
    }
}

.lab-join {
    background-repeat: no-repeat;
    background-size: cover;
    height: 1124px;
    box-sizing: border-box;
    padding-top: px2rem(90px);
    @media screen and (max-width: 1024px) {
        height: auto;
        padding-bottom: px2rem(180px);
        padding-top: px2rem(120px);
    }
    &-title {
        font-size: 20px;
        color: #fff;
        text-align: center;
        display: flex;
        flex-flow: column;
        align-items: center;
        @media screen and (max-width: 1024px) {
            font-size: px2rem(30px);
        }
        .b {
            font-size: 40px;
            font-weight: bold;
            margin-bottom: 5px;
            @media screen and (max-width: 1024px) {
                font-size: px2rem(50px);
                margin-bottom: px2rem(15px);
            }
        }

        &::after {
            content: '';
            display: inline-block;
            width: 40px;
            height: 5px;
            background-color: #138aff;
            border-radius: 3px;
            margin-top: 20px;
        }
    }
    &-container {
        background-image: linear-gradient(-30deg, #2510a0 0%, #92636e 100%);
        border-radius: 15px;
        padding: 2px;
        // overflow: hidden;
        position: relative;
        box-shadow: 0px 2px 92px 0px rgba(9, 1, 35, .65);
        transform: translate(-11px, 15px);
        @media screen and (max-width: 1024px) {
            transform: translate(-5px, 10px);
        }
    }
    &-box {
        position: relative;
        z-index: 10;
        margin-top: px2rem(50px);
    }
    &-bg {
        width: 100%;
        height: 100%;
        background-image: linear-gradient(-30deg, #2510a0 0%, #92636e 100%);
        border-radius: 15px;
        padding: 2px;
        overflow: hidden;
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        display: flex;
        &::after {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            background-image: linear-gradient(-30deg, #240b54 0%, #33043d 100%);
            border-radius: 15px;
        }
    }
    &-content {
        height: 740px;
        overflow: hidden;
        border-radius: 15px;
        position: relative;
        z-index: 10;
        @media screen and (max-width: 1024px) {
            height: px2rem(1500px);
        }
    }

    &-item {
        height: 100%;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding-left: 60px;
        padding-top: 70px;
        box-sizing: border-box;
        @media screen and (max-width: 1024px) {
            padding: px2rem(50px) px2rem(30px) 0;
            background-image: var(--mb) !important;
        }
    }
    .info-box {
        width: 478px;
        @media screen and (max-width: 1024px) {
            width: 100%;
        }
        .num {
            font-size: 32px;
            color: rgba(#fff, .4);
            font-family: Arial;
            font-weight: bold;
            margin-bottom: 10px;
            @media screen and (max-width: 1024px) {
                font-size: px2rem(56px);
            }
        }
        .title {
            font-size: 26px;
            color: #fff;
            margin-bottom: 30px;
            @media screen and (max-width: 1024px) {
                font-size: px2rem(40px);
            }
            .b {
                font-weight: bold;
                font-size: 35px;
                margin-bottom: 6px;
                @media screen and (max-width: 1024px) {
                    font-size: px2rem(60px);
                }
            }
        }
        .info {
            font-size: 16px;
            color: rgba(#fff, .8);
            line-height: 2;
            margin-bottom: 24px;
            @media screen and (max-width: 1024px) {
                font-size: px2rem(30px);
            }
        }
        .join {
            font-size: 16px;
            line-height: 30px;
            color: #fff;
            @media screen and (max-width: 1024px) {
                font-size: px2rem(30px);
            }
            a {
                color: #fff;
            }
            .join-btn {
                color: #1387fb;
                cursor: pointer;
                display: inline-block;
            }
            .w {
                @media screen and (max-width: 1024px) {
                    display: flex;
                    flex-flow: column;
                }
                span {
                    &:not(:last-child) {
                        margin-right: 40px;
                        @media screen and (max-width: 1024px) {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }

    &-tab {
        display: flex;
        height: 140px;
        position: absolute;
        left: 2px;
        bottom: 2px;
        width: calc(100% - 4px);
        border-top: 1px solid rgba(#fff, .1);
        align-items: flex-end;
        z-index: 20;
        @media screen and (max-width: 1024px) {
            height: px2rem(240px);
        }
        .tab-item {
            flex: 1;
            height: 100%;
            box-sizing: border-box;
            padding-left: 35px;
            padding-top: 35px;
            cursor: pointer;
            transition: .5s;
            position: relative;
            z-index: 5;
            overflow: hidden;
            background-color: rgba(#200d50, .5);
            @media screen and (max-width: 1024px) {
                padding: px2rem(20px) px2rem(20px) 0;
            }
            &:not(:last-child) {
                border-right: 1px solid rgba(#fff, .1);
            }
            @media screen and (min-width: 1024px) {
                &:hover {
                    height: 160px;
                    border-radius: 10px 10px 0 0;
                    @media screen and (max-width: 1024px) {
                        height: px2rem(280px);
                    }
                    .num {
                        color: #fff;
                    }
                    .title {
                        font-weight: 700;
                        font-size: 28px;
                        @media screen and (max-width: 1024px) {
                            font-size: px2rem(40px);
                        }
                    }
                    &::after {
                        opacity: 1;
                    }
                }
            }
            &.on {
                height: 160px;
                border-radius: 10px 10px 0 0;
                @media screen and (max-width: 1024px) {
                    height: px2rem(280px);
                }
                .num {
                    color: #fff;
                }
                .title {
                    font-weight: 700;
                    font-size: 28px;
                    @media screen and (max-width: 1024px) {
                        font-size: px2rem(40px);
                    }
                }
                &::after {
                    opacity: 1;
                }
            }
            &:nth-child(1) {
                border-radius: 0 0 0 15px;
                &.on,
                &:hover {
                    border-radius: 10px 10px 0 15px;
                }
            }
            &:nth-child(3) {
                border-radius: 0 0 15px;
                &.on,
                &:hover {
                    border-radius: 10px 10px 15px 0;
                }
            }
            &::after {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                z-index: -1;
                width: 100%;
                height: 100%;
                background-image: linear-gradient(180deg, #3e009a 0%, #720181 100%);
                opacity: 0;
                transition: .5s;
            }
            .num {
                font-size: 32px;
                font-weight: bold;
                color: rgba(#fff, .3);
                margin-bottom: 10px;
                transition: .5s;
                @media screen and (max-width: 1024px) {
                    font-size: px2rem(50px);
                }
            }
            .title {
                font-size: 20px;
                color: #fff;
                transition: .5s;
                @media screen and (max-width: 1024px) {
                    font-size: px2rem(32px);
                }
            }
        }
    }
}