.social-growth {
    overflow: hidden;
}
.social-top {
    position: relative;

    &-word {
        position: absolute;
        z-index: 1;
        left: 50%;
        top: 140px;
        margin-left: -588px;

        @media (max-width: 1280px) {
            top: 125px;
            margin-left: -460px;
        }

        @media (max-width: 960px) {
            margin-left: -425px;
        }

        @media (max-width: 900px) {
            display: none;
        }

        p {
            font-size: 35px;
            font-weight: 700;
            line-height: 40px;
            color: #000;

            &:nth-child(1) {
                font-size: 50px;
                font-weight: bold;
                margin-bottom: 10px;
                color: #000;
            }
        }
    }

    &-i {
        display: block;
        height: 580px;

        @media (max-width: 900px) {
            height: auto;

            img {
                width: 100%;
            }
        }

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        // display: block;
        // position: relative;
        // overflow: hidden;
        // width: 100%;
        // height: 100%;
        // padding-top: percentage(580 / 1920);

        // img {
        //     width: 100%;
        //     height: 100%;
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     object-fit: cover;
        //     overflow: hidden;
        //     box-sizing: border-box;
        // }
    }
}


.social-vs {
    // height: 767px;
    padding-top: 80px;
    padding-bottom: 70px;
    max-width: 1200px;
    margin: auto;

    @media (max-width: 1240px) {
        padding: px2rem(70px) px2rem(20px) px2rem(70px) px2rem(20px);
    }

    @media (max-width: 768px) {
        padding: px2rem(90px) px2rem(40px) px2rem(100px);
    }

    .t {
        text-align: center;
        font-size: 45px;
        line-height: 70px;
        letter-spacing: 2px;
        color: #333;
        font-weight: 700;

        @media (max-width: 1024px) {
            line-height: 0;
            font-size: 34px;
        }

        @media (max-width: 768px) {
            font-size: 18px;
        }

        @media (max-width: 500px) {
            line-height: 35px;
        }
    }

    .p {
        text-align: center;
        font-size: 26px;
        line-height: 30px;
        color: #333;

        @media (max-width: 1024px) {
            line-height: 65px;
            font-size: 20px;
        }

        @media (max-width: 768px) {
            font-size: 14px;
            line-height: 50px;
        }

        @media (max-width: 500px) {
            line-height: 22px;
        }
    }


    .i-p-l {
        top: 50px;
        left: 60px;
        z-index: 2;
        position: absolute;
        font-size: 22px;
        line-height: 40px;
        color: #fff;
        display: flex;
        margin-right: 20px;

        @media (max-width: 1024px) {
            left: 10px;
        }

        @media (max-width: 768px) {
            font-size: 18px;
            flex-direction: column;
            margin-top: -37px;
        }

        @media (max-width: 600px) {
            font-size: 16px;
            line-height: 24px;
        }

        img {
            width: px2rem(100px);
            height: px2rem(100px);
            transform: rotate(0deg);
            border-radius: 50%;
            box-shadow: 0px 15px 15px 0px rgba(0, 113, 61, .35);

            @media (max-width: 768px) {
                margin-bottom: 20px;
            }
        }

        &:hover {
            .i-p-i {
                animation-duration: .8s;
                animation-name: jello;
                transform-origin: center;
                animation-fill-mode: both;
            }
        }

        @keyframes jello {

            from,
            11.1%,
            to {
                -webkit-transform: translate3d(0, 0, 0);
                transform: translate3d(0, 0, 0);
            }

            22.2% {
                -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
                transform: skewX(-12.5deg) skewY(-12.5deg);
            }

            33.3% {
                -webkit-transform: skewX(6.25deg) skewY(6.25deg);
                transform: skewX(6.25deg) skewY(6.25deg);
            }

            44.4% {
                -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
                transform: skewX(-3.125deg) skewY(-3.125deg);
            }

            55.5% {
                -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
                transform: skewX(1.5625deg) skewY(1.5625deg);
            }

            66.6% {
                -webkit-transform: skewX(-.78125deg) skewY(-.78125deg);
                transform: skewX(-.78125deg) skewY(-.78125deg);
            }

            77.7% {
                -webkit-transform: skewX(.390625deg) skewY(.390625deg);
                transform: skewX(.390625deg) skewY(.390625deg);
            }

            88.8% {
                -webkit-transform: skewX(-.1953125deg) skewY(-.1953125deg);
                transform: skewX(-.1953125deg) skewY(-.1953125deg);
            }
        }

        &-word {
            margin-left: 20px;

            @media (max-width: 1024px) {
                margin-left: 15px;
            }

            @media (max-width: 600px) {
                margin-left: 10px;
            }

            p {
                &:nth-child(1) {
                    font-size: 28px;
                    // line-height: 60px;
                    color: #fff;

                    @media (max-width: 768px) {
                        font-size: 24px;
                    }

                    @media (max-width: 600px) {
                        font-size: 22px;
                    }

                    @media (max-width: 400px) {
                        font-size: 18px;
                    }
                }

                &:nth-child(2) {
                    width: 35px;
                    height: 5px;
                    margin: 30px 0;
                    background-color: #fff;

                    @media (max-width: 768px) {
                        margin: 15px 0;
                        height: 3px;
                    }
                }
            }
        }
    }

    .i-p-r {
        top: 50px;
        right: 60px;
        z-index: 2;
        position: absolute;
        font-size: 22px;
        line-height: 40px;
        color: #fff;
        display: flex;
        margin-right: 20px;

        @media (max-width: 1024px) {
            right: 10px;
            margin-right: 0;
        }

        @media (max-width: 768px) {
            font-size: 18px;
            flex-direction: column-reverse;
            align-items: flex-end;
            margin-top: -37px;
        }

        @media (max-width: 600px) {
            font-size: 16px;
            line-height: 24px;
        }

        img {
            width: px2rem(100px);
            height: px2rem(100px);
            border-radius: 50%;
            box-shadow: 0px 15px 15px 0px rgba(0, 113, 61, .35);

            @media (max-width: 768px) {
                margin-bottom: 20px;
            }
        }

        &:hover {
            box-shadow: 0;
            .i-p-i {
                animation-duration: .8s;
                animation-name: jello;
                transform-origin: center;
                animation-fill-mode: both;
            }

        }

        &-word {
            margin-right: 20px;
            text-align: right;

            @media (max-width: 1024px) {
                margin-right: 15px;
            }

            @media (max-width: 600px) {
                margin-right: 10px;
            }

            p {
                &:nth-child(1) {
                    font-size: 28px;
                    // line-height: 60px;
                    color: #fff;

                    @media (max-width: 768px) {
                        font-size: 24px;
                    }

                    @media (max-width: 600px) {
                        font-size: 22px;
                    }

                    @media (max-width: 400px) {
                        font-size: 18px;
                    }
                }

                &:nth-child(2) {
                    width: 35px;
                    height: 5px;
                    margin: 30px 0 30px 132px;
                    background-color: #fff;

                    @media (max-width: 768px) {
                        margin: 15px 0 15px 132px;
                        height: 3px;
                    }
                }
            }
        }
    }


    .i-df {
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        margin-top: 40px;

        @media (max-width: 1024px) {
            // margin-top: px2rem(30px);
            margin-top: 0;
        }

        @media (max-width: 500px) {
            margin-top: px2rem(30px);
        }


        .i-1 {
            // width: px2rem(630px);
            // height: px2rem(470px);
            width: 100%;
            overflow: hidden;
            display: block;
            position: relative;
            margin-right: -30px;

            @media (max-width: 1240px) {
                margin-right: -20px;
            }

            @media (max-width: 1024px) {
                margin-right: -60px;
            }

            @media (max-width: 960px) {
                margin-right: -40px;
            }

            @media (max-width: 800px) {
                margin-right: -30px;
            }

            @media (max-width: 666px) {
                margin-right: -25px;
            }

            @media (max-width: 590px) {
                margin-right: -20px;
            }

            @media (max-width: 550px) {
                margin-right: -15px;
            }

            @media (max-width: 500px) {
                margin-right: -10px;
            }

            @media (max-width: 450px) {
                margin-right: -7px;
            }

            @media (max-width: 420px) {
                margin-right: -6px;
            }

            @media (max-width: 400px) {
                margin-right: -4px;
            }

            @media (max-width: 385px) {
                margin-right: -2px;
            }

            @media (max-width: 375px) {
                margin-right: 0;
            }

            // @media (max-width: 1024px) {
            // width: px2rem(390px);
            // height: px2rem(570px);
            // }

            .i {
                display: block;
                // width: 100%;
                // height: 100%;
                object-fit: cover;
                position: relative;
                overflow: hidden;
                padding-top: 75%;
                // padding-top: percentage(470 / 470);
                width: 100%;

                @media (max-width: 1024px) {
                    display: none;
                }

                img {
                    position: absolute;
                    object-fit: cover;
                    width: 100%;
                    top: 0;
                    left: 0;
                }
            }
        }

        .i-phone {
            display: none;
            position: relative;
            overflow: hidden;
            padding-top: percentage(630 / 390);
            width: 100%;

            @media (max-width: 1024px) {
                display: block;
            }

            img {
                position: absolute;
                object-fit: cover;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
            }
        }

        .i-2 {
            z-index: 1;
            display: block;
            position: absolute;
            overflow: hidden;
            width: px2rem(180px);
            height: px2rem(180px);

            @media (max-width: 1240px) {
                width: px2rem(140px);
                height: px2rem(140px);
            }

            @media (max-width: 1024px) {
                width: px2rem(120px);
                height: px2rem(120px);
            }

            // height: 100%;
            // padding-top: percentage(180 / 180);
            .i {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .i-3 {
            // width: px2rem(630px);
            // height: px2rem(470px);
            width: 100%;
            overflow: hidden;
            margin-left: -30px;
            position: relative;

            @media (max-width: 1240px) {
                margin-left: -20px;
            }

            @media (max-width: 1024px) {
                margin-left: -30px;
            }

            // @media (max-width: 1024px) {
            // width: px2rem(390px);
            // height: px2rem(570px);
            // }

            .i {
                display: block;
                // width: 100%;
                // height: 100%;
                object-fit: cover;
                position: relative;
                overflow: hidden;
                padding-top: 75%;
                width: 100%;

                @media (max-width: 1024px) {
                    display: none;
                }

                img {
                    position: absolute;
                    object-fit: cover;
                    width: 100%;
                    top: 0;
                    left: 0;
                }
            }
        }
    }
}

.social-wp1200 {
    max-width: 1200px;
    margin: auto;
    z-index: 1;

    @media (max-width: 1240px) {
        margin: 0 px2rem(40px);
    }


    .title {
        text-align: center;
        font-size: 50px;
        line-height: 30px;
        color: #1386f9;
        font-weight: 700;

        @media (max-width: 1024px) {
            font-size: 34px;
            line-height: 54px;
        }

        @media (max-width: 768px) {
            font-size: 18px;
        }

        &s {
            margin-bottom: 10px;
            text-align: center;
            font-size: 40px;
            line-height: 30px;
            color: #1386f9;
            font-weight: 700;

            @media (max-width: 1024px) {
                font-size: 34px;
                line-height: 8px;
                margin-bottom: 10px;
            }

            @media (max-width: 768px) {
                padding-top: px2rem(20px);
                font-size: 18px;
                line-height: 0;
                margin-bottom: 0;
            }
        }
    }

    .t {
        text-align: center;
        font-size: 45px;
        line-height: 70px;
        letter-spacing: 2px;
        color: #333;
        font-weight: 700;

        @media (max-width: 1024px) {
            line-height: 0;
            font-size: 32px;
        }

        @media (max-width: 600px) {
            line-height: 23px;
        }

        @media (max-width: 768px) {
            font-size: 18px;
            margin-top: 0;
            // margin-top: 10px;
        }
    }

    .p {
        text-align: center;
        color: #333;
        line-height: 35px;
        @include articleReset(26px, 30px);
        // @include multiEllipsis(2);

        @media (max-width: 1024px) {
            line-height: 65px;
            font-size: 20px;
        }

        @media (max-width: 768px) {
            font-size: 14px;
            line-height: 50px;
        }

        @media (max-width: 600px) {
            line-height: 30px;
        }

        // @media (max-width: 500px) {
        //     font-size: 14px;
        //     line-height: 37px;
        // }
    }

    .p-b {
        font-size: 30px;
        line-height: 60px;
        text-align: center;
        margin: auto;
        margin-top: 20px;
        color: #333;
        width: 100%;
        background: linear-gradient(to top, #cee7ff 20%, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 0%);

        @media (max-width: 1024px) {
            font-size: 20px;
            line-height: 40px;
        }

        @media (max-width: 768px) {
            font-size: 14px;
            line-height: 55px;
        }
    }

    .p-b-t {
        width: 100%;
        text-align: center;
        margin: auto;
        font-size: 30px;
        line-height: 60px;
        margin-top: 100px;
        color: #333;
        background: linear-gradient(to top, #cee7ff 20%, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 0%);

        @media (max-width: 1024px) {
            font-size: 20px;
            line-height: 40px;
        }

        // @media (max-width: 768px) {
        // font-size: 12px;
        // }
    }
}

.social-instability-bg {
    background: url('../images/social_growth_bg.jpg') no-repeat;
    background-size: cover;
    // height: 810px;
    position: relative;
    padding-top: 80px;
    padding-bottom: 100px;

    @media (max-width: 1024px) {
        padding: px2rem(100px) 0;
    }

    @media (max-width: 768px) {
        padding: px2rem(90px) 0;
        // padding: px2rem(40px) 0;
    }

    .swiper {
        padding-top: 35px;
        position: relative;

        @media (max-width: 1024px) {
            // padding-top: px2rem(20px);
            padding-top: 0;
        }

        @media (max-width: 600px) {
            padding-top: 15px;
        }

        .swiper-wrapper {
            position: relative;
            width: 100%;
            height: 100%;
            z-index: 1;
            display: flex;
            transition-property: transform;
            box-sizing: content-box;

            @media (max-width: 768px) {
                flex-direction: column;
            }

            .swiper-slide {
                flex-shrink: 0;
                width: 100%;
                height: 100%;
                position: relative;
                transition-property: transform;
                @media (max-width: 768px) {
                    margin-bottom: px2rem(30px);
                    width: 100% !important;
                }
            }
            @keyframes fadeIn {
                from {
                    opacity: 0;
                }
                to {
                    opacity: 1;
                }
            }
        }
    }

    .swiper-slide {
        .t {
            font-size: 26px;
            line-height: 60px;
            color: #333;
            padding-top: 40px;
            @include multiEllipsis(1);

            @media (max-width: 1024px) {
                padding-top: 20px;
                line-height: 40px;
            }

            @media (max-width: 768px) {
                font-size: 18px;
            }
        }

        .p {
            padding: 0 50px;
            color: #333;
            line-height: 30px;
            overflow: hidden;
            @include articleReset(16px, 30px);
            @include multiEllipsis(2);

            @media (max-width: 1024px) {
                padding: 0 35px;
            }

            @media (max-width: 768px) {
                font-size: 14px;
                line-height: 20px;
            }
        }

        .b1 {
            text-align: center;
            width: 380px;
            height: 480px;
            background-color: #fff;
            box-shadow: 0px 1px 40px 0px rgba(0, 77, 154, .05);
            border-radius: 10px;
            padding-top: 60px;

            img {
                width: px2rem(380px);
                height: px2rem(233px);
                object-fit: cover;
            }
        }

        .b2 {
            text-align: center;
            // width: 380px;
            // height: 480px;
            background-color: #fff;
            box-shadow: 0px 1px 40px 0px rgba(0, 77, 154, .05);
            border-radius: 10px;
            padding-bottom: 52px;
            padding-top: 60px;

            @media (max-width: 1024px) {
                padding-bottom: 35px;
                padding-top: 40px;
            }

            .i {
                position: relative;
                overflow: hidden;
                padding-top: percentage(233 / 380);
                width: 100%;

                img {
                    position: absolute;
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                }
            }
        }

        .b3 {
            text-align: center;
            width: 380px;
            height: 480px;
            background-color: #fff;
            box-shadow: 0px 1px 40px 0px rgba(0, 77, 154, .05);
            border-radius: 10px;
            padding-top: 60px;

            img {
                width: px2rem(380px);
                height: px2rem(223px);
                object-fit: cover;
            }
        }
    }
}

.social-solve {
    position: relative;
    padding: 100px 0;

    @media (max-width: 768px) {
        padding: px2rem(90px) 0;
        // padding: px2rem(40px) 0;
    }

    .swiper-slide {
        .box1 {
            // width: 380px;
            // height: 365px;
            background-color: #f5f8fa;
            border-radius: 10px;
            border: solid 1px #dfeaf4;
            text-align: center;
            padding-top: 10px;
            padding-bottom: 46px;

            @media (max-width: 1024px) {
                padding-bottom: 30px;
            }

            .i {
                position: relative;
                overflow: hidden;
                padding-top: percentage(200 / 380);
                width: 100%;

                img {
                    position: absolute;
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                }
            }

            .t {
                text-align: center;
                font-size: 26px;
                line-height: 60px;
                padding-top: 20px;
                color: #333;
                @include multiEllipsis(1);

                @media (max-width: 768px) {
                    font-size: 18px;
                    padding-top: 0;
                }
            }

            .p {
                text-align: center;
                color: #666;
                line-height: 30px;
                @include articleReset(20px, 30px);
                @include multiEllipsis(1);

                @media (max-width: 1240px) {
                    margin: 0 px2rem(30px);
                }

                @media (max-width: 768px) {
                    margin: -13px px2rem(30px) 0 px2rem(30px);
                    // font-size: 14px;
                    font-size: 16px;
                    line-height: 20px;
                }
            }
        }

        .box2 {
            width: 380px;
            height: 365px;
            background-color: #f5f8fa;
            border-radius: 10px;
            border: solid 1px #dfeaf4;
            text-align: center;
            padding-top: 50px;

            img {
                width: px2rem(380px);
                height: px2rem(200px);
            }

            .t {
                text-align: center;
                font-size: 26px;
                line-height: 60px;
                padding-top: 20px;
                color: #333;
                @include multiEllipsis(1);
            }

            .p {
                text-align: center;
                color: #666;
                line-height: 30px;
                @include articleReset(20px, 30px);
                @include multiEllipsis(1);
            }
        }

        .box3 {
            width: 380px;
            height: 365px;
            background-color: #f5f8fa;
            border-radius: 10px;
            border: solid 1px #dfeaf4;
            text-align: center;
            padding-top: 50px;

            img {
                width: px2rem(380px);
                height: px2rem(200px);
            }

            .t {
                text-align: center;
                font-size: 26px;
                line-height: 60px;
                padding-top: 20px;

                color: #333;
                @include multiEllipsis(1);
            }

            .p {
                text-align: center;
                color: #666;
                line-height: 30px;
                @include articleReset(20px, 30px);
                @include multiEllipsis(1);
            }
        }
    }
}


.social-teacher-bg {
    padding-top: 100px;
    position: relative;
    padding-bottom: 120px;
    background: url('../images/social_teacher_bg.png') no-repeat;
    background-size: cover;

    @media (max-width: 1024px) {
        padding-top: px2rem(90px);
    }

    @media (max-width: 768px) {
        padding-bottom: 156px;
    }


    &-box {
        margin-top: 40px;
        border-radius: 10px;

        @media (max-width: 768px) {
            margin-top: px2rem(20px);
        }

        .t {
            font-size: 32px;
            line-height: 70px;
            color: #fff;
            width: 100%;
            height: 70px;
            background-color: #1386f9;
            box-shadow: 0px 1px 40px 0px rgba(0, 77, 154, .05);
            border-radius: 10px 10px 0px 0px;

            @media (max-width: 768px) {
                font-size: 25px;
            }

            @media (max-width: 500px) {
                font-size: 20px;
            }

            @media (max-width: 415px) {
                font-size: 18px;
            }

            @media (max-width: 360px) {
                font-size: 16px;
            }
        }
    }

    .wordls {
        display: flex;
        background: #fff;
        height: 500px;

        @media (max-width: 1240px) {
            height: auto;
            flex-direction: column;
        }

        .content {
            display: flex;
        }

        .im {
            width: px2rem(220px);
            // height: px2rem(300px);
            overflow: hidden;
            margin-right: 25px;
            border-radius: 10px;

            @media (max-width: 600px) {
                margin-right: 0;
                // width: px2rem(300px);
                width: 150px;
            }

            .i {
                position: relative;
                overflow: hidden;
                padding-top: percentage(300 / 220);
                width: 100%;
                transition: all .4s;

                @media (max-width: 768px) {
                    padding-top: 0;
                    height: 100%;
                }

                img {
                    position: absolute;
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;

                    @media (max-width: 768px) {
                        position: absolute;
                        object-fit: cover;
                        max-width: 100%;
                        max-height: 100%;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 100%;
                        height: 100%;
                    }
                }

                &:hover {
                    transform: scale(1.05);
                }
            }

        }

        .fle {
            flex: 1;
            overflow: hidden;

            @media (max-width: 600px) {
                margin-left: 10px;
            }
        }


        .btnn {
            display: block;
            box-shadow: 2px 2px 2px #f24427;
            width: 150px;
            height: 50px;
            margin-right: 18px;
            background-image: linear-gradient(90deg, #fd854d 0%, #fd674f 100%), linear-gradient(#1f78fd, #1f78fd);
            background-blend-mode: normal, normal;
            border-radius: 25px;
            font-size: 16px;
            line-height: 48px;
            color: #fff;
            text-align: center;
            cursor: pointer;
            @media (max-width: 640px) {
                width: 110px;
                height: 35px;
                font-size: 12px;
                line-height: 35px;
                margin-right: px2rem(20px);
            }

            @media (max-width: 376px) {
                width: 90px;
                height: 35px;
            }

            @media (max-width: 360px) {
                width: 80px;
                height: 33px;
            }

            &:hover {
                transition: all 1s;
                background-image: linear-gradient(90deg, #ffae09 0%, #ed7500 100%), linear-gradient(#1f78fd, #1f78fd);
                background-blend-mode: normal, normal;
                border-radius: 25px;
            }

            &:hover .im {
                transform: scale(1.05);
            }
        }

        &-box {
            width: 50%;
            position: relative;
            padding: 40px 30px;
            display: flex;
            box-sizing: border-box;
            overflow: hidden;
            flex-flow: column;

            @media (max-width: 1240px) {
                width: 100%;
            }

            @media (max-width: 1024px) {
                padding: px2rem(35px) px2rem(30px) 0;
            }

            @media (max-width: 768px) {
                padding: px2rem(30px) px2rem(30px) 0;
            }


            &-title {
                font-size: 28px;
                line-height: 35px;
                color: #333;
                font-weight: 700;
                padding-top: 14px;

                @media (max-width: 472px) {
                    font-size: 18px;
                    line-height: 0px;
                    color: #333;
                    font-weight: 700;
                    padding-top: 14px;
                }

                @media (max-width: 365px) {
                    font-size: 16px;
                }

                @media (max-width: 351px) {
                    font-size: 15px;
                }
            }

            &-p {
                font-size: 20px;
                line-height: 30px;
                color: #1386f9;
                font-weight: 700;
                padding-top: 14px;

                @media (max-width: 472px) {
                    font-size: 14px;
                    color: #1386f9;
                    font-weight: 700;
                    padding-top: 0;
                }
            }
        }

        .df {
            display: flex;
        }

        .poa {
            display: flex;
            justify-content: space-between;
            align-items: center;
            // position: absolute;
            left: 30px;
            bottom: 46px;
            width: 540px;
            height: 150px;
            background-color: #f0f8ff;
            border-radius: 10px;
            transform: translateY(-40px);

            &:nth-child(2) {
                @media (max-width: 768px) {
                    bottom: 71px;
                }
            }

            @media (max-width: 1240px) {
                align-items: center;
                // position: absolute;
                left: 30px;
                bottom: 34px;
                width: 100%;
                background-color: #f0f8ff;
                border-radius: 10px;
                transform: translateY(-30px);
            }

            @media (max-width: 768px) {
                height: 120px;
            }

            @media (max-width: 639px) {
                height: 90px;
            }


            p {
                margin-left: 50px;
                position: relative;
                color: #333;
                line-height: 35px;
                @include articleReset(16px, 35px);

                @media (max-width: 500px) {
                    // margin-left: 35px;
                    margin-left: px2rem(60px);
                }

                @media (max-width: 640px) {
                    line-height: 24px;
                    font-size: 12px;
                }

                &::before {
                    content: '';
                    left: -25px;
                    top: 45%;
                    position: absolute;
                    width: 13px;
                    height: 5px;
                    background-color: #1386f9;
                    border-radius: 2px;

                    @media (max-width: 500px) {
                        top: 38%;
                        left: -19px;
                        width: 9px;
                    }
                }
            }


        }

        .poas {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: absolute;
            left: 30px;
            bottom: 46px;
            width: 540px;
            height: 150px;
            background-color: #f0f8ff;
            border-radius: 10px;

            @media (max-width: 1240px) {
                align-items: center;
                position: absolute;
                left: 30px;
                bottom: 34px;
                width: 94%;
                height: auto;
                background-color: #f0f8ff;
                border-radius: 10px;
            }

            p {
                margin-left: 50px;
                position: relative;
                color: #333;
                line-height: 35px;
                @include articleReset(16px, 35px);

                @media (max-width: 500px) {
                    margin-left: 35px;
                }

                @media (max-width: 640px) {
                    line-height: 24px;
                    font-size: 12px;
                }

                &::before {
                    content: '';
                    left: -25px;
                    top: 45%;
                    position: absolute;
                    width: 13px;
                    height: 5px;
                    background-color: #1386f9;
                    border-radius: 2px;

                    @media (max-width: 500px) {
                        left: -19px;
                        width: 9px;
                    }
                }
            }


        }
    }

}

.socialTeacher {
    // position: absolute;
    padding: px2rem(30px) 0;
    overflow: hidden;
    // box-shadow: 0px 1px 30px 0px rgba(0, 77, 154, .1);
    filter: drop-shadow(0px 1px 20px rgba(0, 77, 154, .1));
    @media screen and (max-width: 1024px) {
        filter: drop-shadow(0px 1px px2rem(20px) rgba(0, 77, 154, .1));
    }
    .swiper-slide {
        // overflow: hidden;

        .socialTeacher-swiper {
            height: 253px;
            width: 100%;
            background-color: #fff;
            // box-shadow: 0px 1px 30px 0px rgba(0, 77, 154, .1);
            border-radius: 10px;
            box-sizing: border-box;
            transition: all .4s;

            &:hover {
                // margin-bottom: -126px;
                height: 380px;

                p {
                    transition: all .4s;
                    @include multiEllipsis(6);
                }
            }

            &-article {
                padding: 30px 34px 51px 29px;
                line-height: 30px;
                overflow: hidden;
                @include articleReset(16px, 30px);
                @media screen and (max-width: 1500px) {
                    padding: 20px 25px 35px;
                }
                p {
                    transition: all .4s;
                    overflow: hidden;
                    @include multiEllipsis(2);
                }
            }

            .i {
                width: 80px;
                height: 80px;
                border-radius: 50%;
                object-fit: cover;
                margin-right: 20px;
            }

            .df {
                display: flex;
                border-bottom: 1px solid #dfdfdf;
                padding: 26px 30px 21px;
                align-items: center;
                @media screen and (max-width: 1500px) {
                    padding: 20px 20px 21px;
                }
            }

            .t {
                font-size: 20px;
                line-height: 30px;
                color: #333;
                font-weight: 700;
            }

            .t2 {
                font-size: 16px;
                line-height: 30px;
                color: #333;
            }
        }
    }

}

.sz {
    @media (max-width: 768px) {
        font-size: 20px;
    }
}

.social-teacher-Swiper {
    position: absolute;
    overflow: hidden;
    width: 100%;
    top: -128px;
    // top: -108px;
    z-index: 9;
    padding: 0 76.5px;
    box-sizing: border-box;
    @media (max-width: 1240px) {
        top: -137px;
        padding: 0 45px;
    }
    @media screen and (max-width: 1024px) {
        padding: 0 px2rem(50px);
    }

    @media (max-width: 767px) {
        top: -128px;
    }

    // @media (max-width: 749px) {
    // top: -116px;
    // }
    // @media (max-width: 639px) {
    //     top: -109px;
    // }
    @media (max-width: 455px) {
        top: -138px;
    }

    @media (max-width: 372px) {
        top: -167px;
    }

    @media (max-width: 360px) {
        top: -137px;
    }

    .teacher-prev-btn,
    .teacher-next-btn {
        position: absolute;
        top: 135px;
        font-size: 36px;
        z-index: 10;
        cursor: pointer;
        color: #666;
        @media screen and (max-width: 1440px) {
            font-size: 32px;
        }
        @media screen and (max-width: 1024px) {
            font-size: 28px;
            top: 130px;
        }
    }
    .teacher-prev-btn {
        left: 25px;
        transform: rotate(90deg);
        @media screen and (max-width: 1240px) {
            left: 8px;
        }
        @media screen and (max-width: 1024px) {
            left: 0;
        }

    }
    .teacher-next-btn {
        right: 25px;
        transform: rotate(-90deg);
        @media screen and (max-width: 1240px) {
            right: 8px;
        }
        @media screen and (max-width: 1024px) {
            right: 0;
        }
    }
}

.social-teacher-article {
    color: #666;
    line-height: 30px;
    margin-top: 20px;
    padding-bottom: 50px;
    @include articleReset(16px, 30px);

    @media (max-width: 750px) {
        margin-top: 12px;
    }

    @media (max-width: 612px) {
        margin-top: 0;
        line-height: 20px;
        @include articleReset(14px, 20px);
    }
}

.socialInstabilitySwiper {
    @media (max-width: 750px) {
        overflow: hidden;
    }
}

.socialSolveSwiper {
    padding-top: 40px;

    @media (max-width: 750px) {
        overflow: hidden;
        position: relative;
    }

    @media (max-width: 1024px) {
        // padding-top: px2rem(30px);
        padding-top: 0;
    }

    @media (max-width: 600px) {
        padding-top: 15px;
    }

    .swiper-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        z-index: 1;
        display: flex;
        transition-property: transform;
        box-sizing: content-box;

        @media (max-width: 768px) {
            flex-direction: column;
        }

        .swiper-slide {
            flex-shrink: 0;
            width: 100%;
            height: 100%;
            position: relative;
            transition-property: transform;

            @media (max-width: 768px) {
                margin-bottom: px2rem(30px);
                width: 100% !important;
            }
        }
    }
}

.socialCourseSwiper {
    @media (max-width: 750px) {
        overflow: hidden;
        position: relative;
        padding-bottom: 30px;
    }
}

.social-course {
    position: relative;
    padding-top: 190px;
    padding-bottom: 100px;

    @media (max-width: 768px) {
        // padding-top: px2rem(300px);
        padding-bottom: px2rem(90px);
    }

    .swiper-slide {
        .box1 {
            // height: 546px;
            border-radius: 10px;
            background-color: #f5f8fa;
            border: solid 1px #dfeaf4;
            padding-bottom: 50px;
            padding-top: 50px;

            @media (max-width: 768px) {
                padding: px2rem(40px) 0;
            }


            .i {
                position: relative;
                overflow: hidden;
                padding-top: percentage(210 / 380);
                width: 100%;

                // &:nth-child(1) {
                //     padding-top: 54.63158%;
                // }

                img {
                    position: absolute;
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                }
            }

            .t {
                text-align: center;
                font-size: 30px;
                line-height: 48px;
                color: #333;
                padding: 60px 45px 0;

                @media (max-width: 1240px) {
                    padding: 30px 45px 0;
                }

                @media (max-width: 880px) {
                    padding: 20px 30px 0;
                    font-size: 25px;
                }

                @media (max-width: 768px) {
                    font-size: 18px;
                    margin-top: 0;
                }
            }

            .p {
                padding: 0 45px;
                text-align: center;
                color: #666;
                line-height: 32px;
                margin-top: 10px;
                margin-bottom: 33px;
                @include articleReset(16px, 32px);
                @include multiEllipsis(2);

                @media (max-width: 1240px) {
                    padding: 0 26px;
                }

                @media (max-width: 768px) {
                    font-size: 14px;
                    line-height: 20px;
                    margin-top: 0;
                    margin-bottom: 20px;
                }
            }

            .buttom {
                box-shadow: 2px 2px 2px #f24427;
                margin: 0 auto;
                margin-top: -10px;
                text-align: center;
                width: 150px;
                height: 50px;
                background-image: linear-gradient(90deg, #fd854d 0%, #fd674f 100%), linear-gradient(#1f78fd, #1f78fd);
                background-blend-mode: normal, normal;
                border-radius: 25px;
                font-size: 16px;
                line-height: 46px;
                color: #fff;
                transition: all .4s;
                cursor: pointer;
                @media (max-width: 768px) {
                    margin-bottom: 10px;
                }

                @media (max-width: 640px) {
                    width: 110px;
                    height: 35px;
                    font-size: 12px;
                    line-height: 35px;
                    // margin-right: px2rem(20px);
                }

                @media (max-width: 376px) {
                    width: 90px;
                    height: 35px;
                }

                @media (max-width: 360px) {
                    width: 80px;
                    height: 33px;
                }

                a {
                    color: #fff;
                }

                &:hover {
                    background-image: linear-gradient(90deg, #ffae09 0%, #ed7500 100%), linear-gradient(#1f78fd, #1f78fd);
                    background-blend-mode: normal, normal;
                    border-radius: 25px;
                }

            }
        }

        .box2 {
            width: 380px;
            height: 365px;
            background-color: #f5f8fa;
            border-radius: 10px;
            border: solid 1px #dfeaf4;
            text-align: center;
            padding-top: 50px;

            img {
                width: px2rem(380px);
                height: px2rem(200px);
            }

            .t {
                text-align: center;
                font-size: 26px;
                line-height: 60px;
                padding-top: 20px;
                color: #333;
                @include multiEllipsis(1);
            }

            .p {
                text-align: center;
                color: #666;
                line-height: 30px;
                @include articleReset(20px, 30px);
                @include multiEllipsis(1);
            }
        }

        .box3 {
            width: 380px;
            height: 546px;
            background-color: #f5f8fa;
            border-radius: 10px;
            border: solid 1px #dfeaf4;
            padding-top: 50px;

            img {
                width: px2rem(380px);
                height: px2rem(200px);
            }

            .t {
                text-align: center;
                font-size: 26px;
                line-height: 60px;
                padding-top: 20px;

                color: #333;
                @include multiEllipsis(1);
            }

            .p {
                text-align: center;
                color: #666;
                line-height: 30px;
                @include articleReset(20px, 30px);
                @include multiEllipsis(1);
            }
        }
    }
}


.social-mature {
    padding-bottom: 100px;

    @media (max-width: 768px) {
        padding-bottom: px2rem(90px);
    }

    .socialMatureSwiper {
        padding-top: 35px;

        @media (max-width: 1024px) {
            padding-top: px2rem(25px);
            // padding-top: 0;
        }

        @media (max-width: 750px) {
            overflow: hidden;
            position: relative;
            padding-bottom: 30px;
        }

        .i {
            position: relative;
            overflow: hidden;
            padding-top: percentage(215 / 380);
            width: 100%;

            img {
                position: absolute;
                object-fit: cover;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
            }
        }

        .box {
            padding-top: 30px;
            padding-bottom: 48px;
            border-radius: 10px;
            background-color: #f5f8fa;
            border: solid 1px #dfeaf4;
            text-align: center;



            .t {
                margin-top: 5px;
                font-size: 26px;
                line-height: 60px;
                color: #333;

                @media (max-width: 768px) {
                    font-size: 20px;
                }
            }

            .p {
                padding: 0 30px;
                font-size: 20px;
                line-height: 30px;
                color: #666;

                @media (max-width: 1240px) {
                    padding: 0 px2rem(30px);
                }

                @media (max-width: 768px) {
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }
    }

    &-bg {
        background: url('../images/social_mature_bg.jpg') no-repeat;
        background-size: cover;
        border-radius: 10px;
        padding: 35px 0;
        display: flex;
        margin-top: 30px;
        justify-content: space-around;

        @media (max-width: 900px) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        @media (max-width: 768px) {
            padding: px2rem(100px) 0;
            margin-top: px2rem(90px);
        }

        &-word {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            margin-left: -50px;

            // margin-top: -60px;
            @media (max-width: 1240px) {
                margin-left: 0;
            }

            @media (max-width: 900px) {
                margin-top: 0;
                margin-left: -20px;
                // margin-left: -135px;
                margin-bottom: 20px;
            }

            @media (max-width: 600px) {
                margin-left: -100px;
            }

            @media (max-width: 550px) {
                margin-left: -50px;
            }

            @media (max-width: 500px) {
                margin-left: -20px;
            }

            .t {
                font-size: 35px;
                line-height: 40px;
                color: #333;
                @include multiEllipsis(1);

                @media (max-width: 900px) {
                    // font-size: 28px;
                    margin-top: 0;
                    font-size: 45px;
                    line-height: 60px;
                    color: #333;
                }

                @media (max-width: 600px) {
                    font-size: 30px;
                    line-height: 50px;
                }

                @media (max-width: 450px) {
                    font-size: 26px;
                    line-height: 40px;
                }


                // @media (max-width: 768px) {
                // font-size: 18px;
                // }
            }

            .p {
                margin-top: 20px;
                font-size: 20px;
                line-height: 35px;
                color: #333;
                @include multiEllipsis(4);

                @media (max-width: 900px) {
                    font-size: 32px;
                    line-height: 50px;
                    color: #333;
                }

                @media (max-width: 768px) {
                    margin-top: 5px;
                    // font-size: 14px;
                    // line-height: 20px;
                }

                @media (max-width: 600px) {
                    font-size: 20px;
                    line-height: 35px;
                }

                @media (max-width: 450px) {
                    font-size: 17px;
                    line-height: 30px;
                }


                p {
                    margin-bottom: 0;
                }
            }

            .btn {
                box-shadow: 2px 2px 2px #f24427;
                margin-top: 35px;
                margin-left: -10px;
                text-align: center;
                width: 150px;
                height: 50px;
                background-image: linear-gradient(90deg, #fd854d 0%, #fd674f 100%), linear-gradient(#1f78fd, #1f78fd);
                background-blend-mode: normal, normal;
                border-radius: 25px;
                font-size: 16px;
                line-height: 47px;
                color: #fff;
                transition: all .4s;
                cursor: pointer;
                @media (max-width: 900px) {
                    margin-top: 0;
                    margin-left: 0;
                }

                @media (max-width: 768px) {
                    font-size: 12px;
                    line-height: 34px;
                    width: 125px;
                    height: 35px;
                    margin-top: px2rem(20px);
                }

                @media (max-width: 640px) {
                    width: 110px;
                    height: 35px;
                    font-size: 12px;
                    line-height: 35px;
                }

                @media (max-width: 376px) {
                    width: 100px;
                    height: 35px;
                }

                @media (max-width: 360px) {
                    width: 100px;
                    height: 33px;
                }


                &:hover {
                    background-image: linear-gradient(90deg, #ffae09 0%, #ed7500 100%), linear-gradient(#1f78fd, #1f78fd);
                    background-blend-mode: normal, normal;
                    border-radius: 25px;
                }
            }
        }

        &-i {
            width: px2rem(400px);
            height: px2rem(360px);

            @media (max-width: 900px) {
                width: 90%;
                // width: 80%;
                height: 100%;
            }

            .i {
                position: relative;
                overflow: hidden;
                padding-top: percentage(360 / 400);
                width: 100%;

                img {
                    position: absolute;
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                }
            }
        }
    }
}

.social-increase {
    padding-bottom: 100px;

    @media (max-width: 768px) {
        padding-bottom: px2rem(90px);
    }

    &-box {
        flex-direction: column;
        // height: 920px;
        margin-top: 28px;
        padding: 50px;
        border-radius: 10px;
        display: flex;
        background-color: #f5f8fa;
        border: solid 1px #dfeaf4;

        @media (max-width: 1024px) {
            margin-top: 0;
        }

        @media (max-width: 768px) {
            margin-top: px2rem(25px);
            padding: px2rem(30px);
        }

        &-df {
            display: flex;

            @media (max-width: 900px) {
                justify-content: center;
                align-items: center;
                flex-direction: column;
            }
        }

        &-i {
            height: 100%;
            width: 654 / 1098 * 100%;

            @media (max-width: 900px) {
                // margin-top: px2rem(40px);
                margin-top: 0;
                width: 100%;
            }

            .img-box {
                position: relative;
                overflow: hidden;
                padding-top: 412 / 654 * 100%;
            }

            img {
                border-radius: 20px;
                position: absolute;
                object-fit: cover;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
            }
        }

        &-word {
            display: flex;
            flex-direction: column;
            margin: auto;
            margin-top: 60px;
            margin-left: 56px;

            @media (max-width: 1240px) {
                margin-left: 30px;
            }

            @media (max-width: 900px) {
                margin-left: 28px;
                margin-top: 10px;
            }

            @media (max-width: 768px) {
                margin-left: 15px;
                margin-top: 0;
            }

            .t {
                text-align: inherit;
                font-size: 35px;
                line-height: 48px;
                color: #333;

                @media (max-width: 768px) {
                    font-size: 18px;
                }
            }

            .p {
                width: 307px;
                margin-top: 20px;
                font-size: 20px;
                line-height: 35px;
                text-align: inherit;
                color: #666;
                @include multiEllipsis(2);

                @media (max-width: 900px) {
                    width: auto;
                }

                @media (max-width: 768px) {
                    font-size: 14px;
                    line-height: 20px;
                    margin-top: 0;
                }
            }

            .btn {
                margin-top: 30px;
                // margin-left: 20px;
                text-align: center;
                width: 190px;
                height: 50px;
                background-image: linear-gradient(90deg, #fd854d 0%, #fd674f 100%), linear-gradient(#1f78fd, #1f78fd);
                background-blend-mode: normal, normal;
                border-radius: 25px;
                font-size: 16px;
                line-height: 47px;
                color: #fff;
                transition: all .4s;
                box-shadow: 2px 2px 2px #f24427;
                cursor: pointer;
                a {
                    display: block;
                    color: #fff;
                }

                &:hover {
                    background-blend-mode: normal, normal;
                    border-radius: 25px;
                    background-image: linear-gradient(90deg, #ffae09 0%, #ed7500 100%), linear-gradient(#1f78fd, #1f78fd);
                }

                @media (max-width: 900px) {
                    margin-top: px2rem(20px);
                    font-size: 14px;
                    line-height: 38px;
                    width: 150px;
                    height: 40px;
                }
            }

        }


        .liner {
            width: 100%;
            height: 2px;
            background-color: #dfeaf4;
            position: absolute;
            top: 50%;
        }

        .text {
            text-align: center;
            height: 76px;
            line-height: 76px;
            margin: 0 auto;
            background-color: #f5f8fa;
            position: relative;
            z-index: 3;
            display: inline-block;
            padding: 0 25px;
        }

        .more {
            margin-top: 60px;
            text-align: center;
            font-weight: 700;
            font-size: 35px;
            line-height: 48px;
            letter-spacing: 1px;
            color: #333;
            position: relative;

            @media (max-width: 1024px) {
                font-size: 34px;
                margin-top: 0;
            }

            @media (max-width: 900px) {
                margin-top: px2rem(50px);
            }

            @media (max-width: 768px) {
                font-size: 18px;
                margin-top: px2rem(60px);
            }
        }

        .socialIncreaseSwiper {
            position: relative;

            @media (max-width: 768px) {
                overflow: hidden;
            }

            .swiper-wrapper {
                position: relative;
                width: 100%;
                height: 100%;
                z-index: 1;
                display: flex;
                transition-property: transform;
                box-sizing: content-box;

                @media (max-width: 768px) {
                    flex-direction: column;
                }

                .swiper-slide {
                    flex-shrink: 0;
                    width: 100%;
                    height: 100%;
                    position: relative;
                    transition-property: transform;

                    @media (max-width: 760px) {
                        margin-bottom: px2rem(30px);
                        width: 100% !important;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }

            .box {
                border: solid 1px #dfeaf4;
                background-color: #fff;
                border-radius: 10px;
                padding-top: 50px;
                padding-bottom: 40px;

                a {
                    display: block;
                }

                .t {
                    font-size: 26px;
                    line-height: 35px;
                    color: #333;
                    padding-top: 30px;
                    padding: 30px 20px 0;

                    @media (max-width: 1024px) {
                        padding: px2rem(20px) 20px 0;
                    }

                    @media (max-width: 768px) {
                        font-size: 18px;
                    }
                }

                .p {
                    padding: 0 20px;
                    color: #666;
                    line-height: 35px;
                    @include articleReset(20px, 35px);
                    @include multiEllipsis(2);

                    @media (max-width: 768px) {
                        font-size: 14px;
                        line-height: 20px;
                    }
                }

                .i {
                    position: relative;
                    overflow: hidden;
                    margin: auto;
                    height: px2rem(90px);
                    width: px2rem(114px);
                    // padding-top: 26.57895%;

                    img {
                        position: absolute;
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                    }
                }
            }
        }
    }
}

.pt-100 {
    text-align: center;
    padding-top: 100px;

    @media (max-width: 1024px) {
        padding-top: px2rem(100px);
    }

    @media (max-width: 768px) {
        padding-top: px2rem(100px);
    }
}

.ind {
    transform: scale(1.05);
}

.pt-18 {
    text-align: center;

    @media (max-width: 768px) {
        padding-top: 18px;
    }
}

.lh-20 {
    @media (max-width: 768px) {
        font-size: 14px !important;
        line-height: 20px !important;
    }
}

.cancel {
    position: absolute;
    top: 2px;
    right: 3px;
}

// .black-overlay {
//     display: none;
//     position: fixed;
//     width: 100%;
//     height: 212%;
//     background: rgba(0, 0, 0, .71);
//     opacity: 1;
//     top: 0%;
//     left: 0%;
//     z-index: 1001;

.white-content {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    z-index: 1002;
    overflow: auto;

    .info-box {
        width: 100%;
        object-fit: cover;
        height: 100%;

        // @media (max-width: 1024px) {
        //     width: 355px;
        // }
        video,
        img {
            width: 100%;
            object-fit: cover;
            height: 100%;
            box-sizing: border-box;

            @media (max-width: 1024px) {
                height: auto;
            }
        }
    }

    .masks {
        text-align: right;
        cursor: default;
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 5;
        font-weight: 700;

        .layer {
            font-size: 16px;
            cursor: pointer;
            padding: 5px;
        }
    }
}

// }

// 遮罩层

.black-overlay {
    display: none;
    position: fixed;
    width: 100%;
    height: 100% !important;
    background: rgba(0, 0, 0, .71);
    opacity: 1;
    top: 0%;
    left: 0%;
    z-index: 1001;

    .white-content {
        // position: absolute;
        position: fixed;
        // top: 34%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) scale(.8);
        color: #fff;
        z-index: 1002;
        overflow: auto;
        transition: all .5s;
        @media (max-width: 768px) {
            width: 600px;
        }

        @media (max-width: 620px) {
            width: 500px;
        }

        @media (max-width: 520px) {
            width: 425px;
        }

        @media (max-width: 500px) {
            width: 338px;
        }

        .form-box {
            border-radius: 40px;
            height: 650px;
            max-width: 620px;
            display: flex;
            background: #fff;

            @media (max-width: 1400px) {
                width: 500px;
                height: 600px;
            }

            @media (max-width: 1024px) {
                height: 477px;
            }

            @media (max-width: 700px) {
                height: 466px;
            }

            @media (max-width: 665px) {
                width: 330px;
            }

            .form-i {
                @media (max-width: 1024px) {
                    display: none;
                }
            }

            &-v {
                width: 100%;
                object-fit: cover;

                @media (max-width: 1024px) {
                    height: auto;
                }
            }

            .form-top {
                width: 500px;
                padding: px2rem(50px);

                @media (max-width: 1400px) {
                    padding: px2rem(30px);
                }

                @media (max-width: 665px) {
                    padding: px2rem(25px);
                }

                @media (max-width: 544px) {
                    padding: px2rem(15px);
                }

                .form-inp {
                    max-width: 420px;
                    margin: auto;
                    padding-top: 10px;

                    li {
                        font-size: 14px;
                        color: #777;
                        padding-top: px2rem(30px);

                        &::before {
                            content: '*';
                            margin-right: 5px;
                            color: red;
                        }

                        input {
                            border: none;
                            padding-top: 10px;
                            border-bottom: 1px solid #dfdfdf;
                            width: 100%;
                        }
                    }
                }

                .btn {
                    text-align: center;
                    display: block;
                    width: 160px;
                    height: 40px;
                    border-radius: 22px;
                    background: #c7000a;
                    color: #fff;
                    border: none;
                    line-height: 37px;
                    appearance: none;
                    cursor: pointer;
                    transition: all .2s;
                    font-size: 16px;
                    margin: 0 auto;
                    margin-top: 45px;
                    font-weight: lighter;
                    letter-spacing: 5px;

                    @media (max-width: 1400px) {
                        margin-top: 15px;
                    }
                }
            }
        }

        .mask {
            text-align: right;
            cursor: default;
            position: absolute;
            top: 16px;
            right: 19px;
            z-index: 5;

            .layer {
                font-size: 18px;
                cursor: pointer;
                padding: 5px;
                z-index: 5;
                color: #000;
                font-weight: 700;
            }
        }
    }

    .scale {
        transform: translate(-50%, -50%) scale(1);
    }
}