.index-section {
    background-color: #fff;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    &.pt-90 {
        padding-top: px2rem(90px);
    }
    &.pb-100 {
        padding-bottom: px2rem(100px);
    }
    &.pt-80 {
        padding-top: px2rem(80px);
    }
    &.pb-20 {
        padding-bottom: px2rem(20px);
    }
    .big-title {
        text-align: center;
        color: #333;
        margin-bottom: px2rem(30px);
        .title {
            font-size: 45px;
            font-weight: bold;
            margin-bottom: 5px;
        }
        .p {
            font-size: 26px;
        }
    }
    .flex-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &.reverse {
            flex-direction: row-reverse;
        }
        .img {
            width: 650px;
            height: 550px;
            position: relative;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                max-width: 100%;
                max-height: 100%;
            }
            .avatar1 {
                position: absolute;
                width: 85px;
                height: 85px;
                left: 7px;
                top: 135px;
                animation-name: b2t;
                animation-fill-mode: forwards;
                animation-duration: 4s;
                animation-iteration-count: infinite;
                animation-timing-function: linear;
            }
            .avatar2 {
                position: absolute;
                width: 71px;
                height: 71px;
                right: 147px;
                top: 103px;
                animation-name: b2t;
                animation-fill-mode: forwards;
                animation-duration: 3s;
                animation-iteration-count: infinite;
                animation-timing-function: linear;
            }
            .avatar3 {
                position: absolute;
                width: 71px;
                height: 71px;
                left: 130px;
                top: 44px;
                animation-name: b2t;
                animation-fill-mode: forwards;
                animation-duration: 5s;
                animation-iteration-count: infinite;
                animation-timing-function: linear;
            }
            .avatar4 {
                position: absolute;
                width: 65px;
                height: 65px;
                right: 124px;
                top: 300px;
                animation-name: l2r;
                animation-fill-mode: forwards;
                animation-duration: 3s;
                animation-iteration-count: infinite;
                animation-timing-function: linear;
            }
            .mobile-box {
                display: none;
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    top: 0;
                    left: 0;
                }
            }
        }
        .video-box {
            position: absolute;
            width: 279px;
            height: 190px;
            overflow: hidden;
            z-index: 4;
            border-radius: 15px;
            top: 72px;
            left: 41px;
            video,
            .mejs__container {
                display: block;
                position: absolute;
                width: 100% !important;
                height: 100% !important;
                left: 0;
                top: 0;
            }
        }
        .video-bg {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 4;
            background-position: center center;
            display: flex;
            align-items: center;
            justify-content: center;
            background-repeat: no-repeat;
            cursor: pointer;
            background-size: cover;
        }
    }
    .info {
        flex: 1;
        overflow: hidden;
        max-width: 480px;
        color: #333;
        .title {
            font-size: 50px;
            font-weight: bold;
            margin-bottom: px2rem(24px);
        }
        .p {
            font-size: 20px;
            line-height: 35px;
            text-align: justify;
        }
        .more {
            margin-top: px2rem(35px);
            display: flex;
            height: 50px;
            width: 180px;
            border-radius: 25px;
            text-align: center;
            justify-content: center;
            align-items: center;
            color: #fff;
            transition: all .2s;
            font-size: 16px;
            background: #1f78fd;
            overflow: hidden;
            i {
                transform: rotate(-90deg);
                margin-left: px2rem(10px);
                margin-top: 2px;
                transition: all .2s;
            }
            &:hover {
                background: lighten(#1f78fd, 5%);
                i {
                    margin-left: px2rem(15px);
                }
            }
        }
    }
    @media (max-width: 800px) {
        .big-title {
            .title {
                font-size: 36px;
            }
            .p {
                font-size: 20px;
            }
        }
        .flex-box {
            display: block;
            .img {
                margin: 0 auto;
            }
        }
        .info {
            max-width: 100%;
            padding-bottom: px2rem(50px);
            .title {
                font-size: 36px;
            }
        }
    }
    @media (max-width: 750px) {
        .big-title {
            .title {
                font-size: 18px;
            }
            .p {
                font-size: 14px;
            }
        }
        .flex-box {
            margin-top: px2rem(30px);
            .img {
                width: 100%;
                padding-top: percentage(550/650);
                height: 0;
                > img {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                }
                .pc-box {
                    display: none;
                }
                .mobile-box {
                    display: block;
                }
            }
            .video-box {
                width: percentage(279/650);
                height: percentage(190/550);
                border-radius: px2rem(15px);
                top: percentage(72/550);
                left: percentage(41/650);
            }
        }
        .info {
            .title {
                font-size: 26px;
            }
            .p {
                font-size: 14px;
                line-height: 25px;
            }
            .more {
                width: 100px;
                height: 30px;
                border-radius: 14px;
                line-height: 30px;
                font-size: 12px;
            }
        }
    }
}
@keyframes b2t {
    0%,
    50%,
    100% {
        transform: translateY(0);
    }
    25% {
        transform: translateY(10px);
    }
    75% {
        transform: translateY(-10px);
    }
}
@keyframes l2r {
    0%,
    50%,
    100% {
        transform: translateY(0);
    }
    25% {
        transform: translateX(10px);
    }
    75% {
        transform: translateX(-10px);
    }
}
.index-small-img-swiper {
    .swiper-slide {
        //background-color: #fff;
        .img-box {
            width: 650px;
            height: 550px;
        }
        .img-mobile {
            display: none;
            position: relative;
            width: 100%;
            padding-top: percentage(550/650);
            overflow: hidden;
            img {
                position: absolute;
                top: 0;
                left: 0;
            }
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
    @media (max-width: 750px) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        .swiper-slide {
            .img-box {
                display: none;
            }
            .img-mobile {
                display: block;
            }
        }
    }
}
.index-title {
    text-align: center;
    position: relative;
    color: #333;
    padding-bottom: 22px;
    &:after {
        position: absolute;
        left: 50%;
        bottom: 0;
        width: 40px;
        height: 5px;
        border-radius: 3px;
        content: '';
        margin-left: -20px;
        background: #138aff;
    }
    .title {
        font-size: 32px;
        font-weight: bold;
        margin-bottom: 5px;
    }
    .p {
        font-size: 20px;
    }
    @media (max-width: 800px) {
        .title {
            font-size: 24px;
        }
        .p {
            font-size: 16px;
        }
    }
    @media (max-width: 750px) {
        .title {
            font-size: 18px;
        }
        .p {
            font-size: 12px;
        }
    }
}
.index-section-img-swiper {
    position: relative;
    width: 100%;
    overflow: hidden;
    border-radius: 8px;
    margin-bottom: -180px;
    .swiper-slide {
        .img {
            position: relative;
            width: 100%;
            padding-top: percentage(360/1200);
            overflow: hidden;
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
    @media (max-width: 750px) {
        margin-bottom: -50px;
        .swiper-slide {
            .img {
                padding-top: percentage(360/710);
            }
        }
    }
}
.index-section-thumb-swiper {
    position: relative;
    z-index: 2;
    padding: 0 30px;
    .swiper-container {
        padding-top: 60px;
        padding-bottom: 10px;
    }
    .box {
        border-radius: 8px;
        background: #fff;
        //overflow: hidden;
        padding: 20px;
        box-shadow: 0 0 10px rgba(#000, .1);
        cursor: pointer;
        transition: all .2s;
        position: relative;
    }
    .title {
        text-align: center;
        font-size: 26px;
        line-height: 35px;
        font-weight: bold;
        color: #333;
        margin-bottom: px2rem(25px);
        padding-top: 20px;
        transition: color .2s;
    }
    .p {
        border: 1px solid #e7edf5;
        border-radius: 4px;
        background: #f8fbff;
        padding: px2rem(20px) px2rem(24px);
        font-size: 16px;
        line-height: 30px;
        color: #666;
        transition: all .2s;
    }
    .swiper-slide-thumb-active {
        .box {
            transform: translateY(-40px);
            background: #1980fe;
            &:before {
                position: absolute;
                border-right: 20px solid transparent;
                border-bottom: 20px solid #1980fe;
                border-left: 20px solid transparent;
                content: '';
                left: 50%;
                top: -20px;
                margin-left: -20px;
            }
        }
        .p {
            border-color: #5fa6fe;
            background: #1f67f2;
            color: #fff;
        }
        .title {
            color: #fff;
        }
    }
    .swiper-pagination {
        display: none;
        position: static;
        padding-top: px2rem(30px);
        &-bullet {
            opacity: 1;
            border: 2px solid #1f78fd;
            background: none;
            &-active {
                background: #1f78fd;
            }
        }
    }
    .thumb-next,
    .thumb-prev {
        position: absolute;
        color: #bdbec0;
        top: 50%;
        font-size: 30px;
        line-height: 40px;
        margin-top: -20px;
        z-index: 5;
        outline: none;
        cursor: pointer;
        display: none;
    }
    .thumb-next {
        right: 0;
        transform: rotate(-90deg);
    }
    .thumb-prev {
        left: 0;
        transform: rotate(90deg);
    }
    @media (max-width: 800px) {
        .title {
            font-size: 20px;
            line-height: 30px;
        }
    }
    @media (max-width: 750px) {
        .swiper-container {
            padding-top: 20px;
        }
        .title {
            padding-top: 0;
        }
        .swiper-slide-thumb-active {
            .box {
                transform: translateY(0);
            }
        }
        .swiper-pagination {
            display: block;
        }
        .thumb-next,
        .thumb-prev {
            display: block;
        }
    }
}
.index-card-swiper {
    //width: 1100px;
    margin: 0 auto;
    padding: 10px 0;
    .swiper-slide {
        width: 1150px;
        height: 470px;
        border: 1px solid #1f78fd;
        background-color: #fff;
        background-image: url(../images/card_bg1.jpg);
        box-sizing: border-box;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 10px;
        overflow: hidden;
        box-shadow: 0 0 10px rgba(#000, .1);
        padding: 0 25px;
        //display: flex;
        //justify-content: space-between;
        //align-items: center;
        position: relative;
    }
    .wrapper {
        display: flex;
        justify-content: space-between;
        //align-items: center;
    }
    .swiper-pagination {
        position: static;
        margin-top: 20px;
        &-bullet {
            border: 2px solid #1f78fd;
            background: none;
            opacity: 1;
            outline: none;
            &-active {
                background: #1f78fd;
            }
        }
    }
    .logo {
        text-align: right;
        height: 80px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: px2rem(34px);
        img {
            height: 32px;
        }
    }
    .intro {
        flex: 1;
        overflow: hidden;
        margin-left: px2rem(40px);
        .title {
            font-size: 50px;
            color: #333;
            margin-bottom: px2rem(20px);
            line-height: 60px;
            strong {
                display: inline-block;
                vertical-align: top;
                position: relative;
                padding-right: px2rem(22px);
                &:after {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    width: px2rem(5px);
                    height: px2rem(36px);
                    margin-top: px2rem(-18px);
                    content: '';
                    background: #333;
                }
            }
        }
        .img {
            width: 644px;
            height: 468px;
            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        .p {
            font-size: 20px;
            color: #333;
            line-height: 32px;
        }
        .li {
            position: relative;
            padding-left: px2rem(50px);
            margin-bottom: 10px;
            &:before {
                position: absolute;
                display: block;
                left: 0;
                content: '';
                width: px2rem(35px);
                height: px2rem(3px);
                background: #333;
                top: 14px;
            }
        }
        .num-box {
            font-size: 0;
            padding-top: px2rem(30px);
            .item {
                display: inline-block;
                vertical-align: top;
                width: 50%;
            }
            .num {
                font-weight: bold;
                font-size: 55px;
                color: #1f78fd;
            }
            p {
                font-size: 18px;
                line-height: 28px;
                color: #333;
            }
        }
    }
    @media (max-width: 1024px) {
        .swiper-slide {
            width: 100%;
            padding: 0;
            height: 385px;
        }
        .img {
            width: 500px;
            img {
                width: 100%;
            }
        }
        .intro {
            .title {
                font-size: 40px;
                line-height: 50px;
            }
            .p {
                font-size: 18px;
            }
            .num-box {
                .num {
                    font-size: 42px;
                }
            }
        }
    }
    @media (max-width: 800px) {
        .swiper-slide {
            height: auto;
        }
        .wrapper {
            display: block;
        }
        .intro {
            margin-left: 0;
            padding: 0 px2rem(40px) px2rem(50px);
        }
        .img {
            max-width: 100%;
            margin: 0 auto;
        }
    }
    @media (max-width: 750px) {
        .logo {
            height: auto;
            padding-top: px2rem(30px);
            padding-bottom: px2rem(30px);
        }
        .intro {
            .title {
                font-size: 26px;
                line-height: 40px;
            }
            .p {
                font-size: 14px;
                line-height: 20px;
            }
            .li {
                margin-bottom: 5px;
                &:before {
                    top: 9px;
                }
            }
            .num-box {
                .num {
                    font-size: 28px;
                }
                p {
                    font-size: 14px;
                }
            }
        }
    }
}
.index-accordion {
    position: relative;
    //height: 470px;
    overflow: hidden;
    padding-top: 30px;
    &-wrapper {
        position: relative;
    }
    &-dots {
        text-align: center;
        margin-top: 20px;
        .dot {
            display: inline-block;
            vertical-align: top;
            width: 8px;
            height: 8px;
            border-radius: 100%;
            border: 2px solid #1f78fd;
            margin: 0 4px;
            cursor: pointer;
            &.active {
                background: #1f78fd;
            }
        }
    }
    .accordion-item {
        width: 1150px;
        height: 470px;
        border: 1px solid #1f78fd;
        background-color: #fff;
        background-image: url(../images/card_bg1.jpg);
        box-sizing: border-box;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 10px;
        overflow: hidden;
        box-shadow: 0 0 10px rgba(#000, .1);
        position: absolute;
        left: 0;
        top: 0;
        transition: all 1s;
        display: block;
    }
    .wrapper {
        display: flex;
        justify-content: space-between;
        //align-items: center;
    }
    .logo {
        text-align: right;
        height: 80px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding-right: px2rem(34px);
        img {
            height: 32px;
        }
    }
    .intro {
        flex: 1;
        overflow: hidden;
        margin-left: px2rem(40px);
        .title {
            font-size: 50px;
            color: #333;
            margin-bottom: px2rem(20px);
            line-height: 60px;
            strong {
                display: inline-block;
                vertical-align: top;
                position: relative;
                padding-right: px2rem(22px);
                &:after {
                    position: absolute;
                    right: 0;
                    top: 50%;
                    width: px2rem(5px);
                    height: px2rem(36px);
                    margin-top: px2rem(-18px);
                    content: '';
                    background: #333;
                }
            }
        }
        .img {
            width: 644px;
            height: 468px;
            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        .p {
            font-size: 20px;
            color: #333;
            line-height: 32px;
        }
        .li {
            position: relative;
            padding-left: px2rem(50px);
            margin-bottom: 10px;
            &:before {
                position: absolute;
                display: block;
                left: 0;
                content: '';
                width: px2rem(35px);
                height: px2rem(3px);
                background: #333;
                top: 14px;
            }
        }
        .num-box {
            font-size: 0;
            padding-top: px2rem(30px);
            .item {
                display: inline-block;
                vertical-align: top;
                width: 50%;
            }
            .num {
                font-weight: bold;
                font-size: 55px;
                color: #1f78fd;
            }
            p {
                font-size: 18px;
                line-height: 28px;
                color: #333;
            }
        }
    }
    @media (max-width: 1024px) {
        .accordion-item {
            width: calc(100% - 30px);
            padding: 0;
            height: 385px;
        }
        .img {
            width: 500px;
            img {
                width: 100%;
            }
        }
        .intro {
            .title {
                font-size: 40px;
                line-height: 50px;
            }
            .p {
                font-size: 18px;
            }
            .num-box {
                .num {
                    font-size: 42px;
                }
            }
        }
    }
    @media (max-width: 800px) {
        .accordion-item {
            height: auto;
        }
        .wrapper {
            display: block;
        }
        .intro {
            margin-left: 0;
            padding: 0 px2rem(40px) px2rem(50px);
        }
        .img {
            max-width: 100%;
            margin: 0 auto;
        }
    }
    @media (max-width: 750px) {
        .logo {
            height: auto;
            padding-top: px2rem(30px);
            padding-bottom: px2rem(30px);
        }
        .intro {
            .title {
                font-size: 26px;
                line-height: 40px;
            }
            .p {
                font-size: 14px;
                line-height: 20px;
            }
            .li {
                margin-bottom: 5px;
                &:before {
                    top: 9px;
                }
            }
            .num-box {
                .num {
                    font-size: 28px;
                }
                p {
                    font-size: 14px;
                }
            }
        }
    }
}
.index-card-btn {
    margin: px2rem(40px) auto 0;
    display: flex;
    height: 50px;
    width: 180px;
    border-radius: 25px;
    text-align: center;
    justify-content: center;
    align-items: center;
    color: #fff;
    transition: all .2s;
    font-size: 16px;
    background: #1f78fd;
    overflow: hidden;
    i {
        transform: rotate(-90deg);
        margin-left: px2rem(10px);
        margin-top: 2px;
        transition: all .2s;
    }
    &:hover {
        background: lighten(#1f78fd, 5%);
        i {
            margin-left: px2rem(15px);
        }
    }
    @media (max-width: 750px) {
        height: 40px;
        width: 150px;
        border-radius: 20px;
        font-size: 12px;
    }
}
.index-service {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    .wp1200 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: px2rem(380px);
    }
    .word {
        flex: 1;
        overflow: hidden;
        color: #fff;
        font-weight: bold;
        .big {
            font-size: px2rem(50px);
        }
        .small {
            font-size: px2rem(32px);
        }
    }
    .service-btn {
        display: flex;
        height: 70px;
        width: 220px;
        border-radius: 35px;
        text-align: center;
        justify-content: center;
        align-items: center;
        color: #fff;
        transition: all .2s;
        font-size: 18px;
        background: #1f78fd;
        overflow: hidden;
        i {
            transform: rotate(-90deg);
            margin-left: px2rem(10px);
            margin-top: 2px;
            transition: all .2s;
        }
        &:hover {
            background: lighten(#1f78fd, 5%);
            i {
                margin-left: px2rem(15px);
            }
        }
    }
    @media (max-width: 750px) {
        .wp1200 {
            box-sizing: border-box;
            padding: 0 px2rem(40px);
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
        }
        .word {
            flex: 0 0 auto;
        }
        .service-btn {
            width: 140px;
            height: 40px;
            font-size: 14px;
            margin-top: px2rem(30px);
        }
    }
}
.index-banner {
    position: relative;
    overflow: hidden;
    .bg {
        width: 100%;
        height: 470px;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .word-1 {
        display: none;
        position: absolute;
        left: 50%;
        font-size: 18px;
        line-height: 28px;
        color: #333;
        width: 302px;
        height: 94px;
        box-sizing: border-box;
        padding: 20px 36px 20px 20px;
        top: 70px;
        margin-left: -560px;
        background-image: url(../images/index_bg5.png);
        font-weight: bold;
    }
    .word-2 {
        display: none;
        position: absolute;
        left: 50%;
        top: 74px;
        margin-left: 348px;
        width: 263px;
        height: 96px;
        box-sizing: border-box;
        padding: 20px 20px 20px 36px;
        background-image: url(../images/index_bg6.png);
        font-size: 18px;
        line-height: 28px;
        color: #333;
        font-weight: bold;
    }
    &-mobile {
        display: none;
        img {
            width: 100%;
            display: block;
        }
    }
    @media (max-width: 1024px) {
        .bg {
            height: 370px;
        }
        .word-1 {
            margin-left: -507px;
        }
        .word-2 {
            left: auto;
            right: 0;
            top: 24px;
        }
    }
    @media (max-width: 750px) {
        display: none;
        &-mobile {
            display: block;
        }
    }
}