.partner-banner {
    position: relative;
    overflow: hidden;
    .bg {
        height: 580px;
        @media screen and (max-width: 1024px) {
            height: px2rem(630px);
        }
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            &:nth-of-type(2) {
                display: none;
            }
            @media screen and (max-width: 1024px) {
                &:nth-of-type(1) {
                    display: none;
                }
                &:nth-of-type(2) {
                    display: inline-block;
                }
            }
        }
    }
    .word {
        position: absolute;
        backface-visibility: hidden;
        left: 50%;
        margin-left: -600px;
        top: 140px;
        z-index: 2;
        width: 600px;
        @media screen and (max-width: 1260px) {
            margin-left: 0;
            width: 100%;
            top: px2rem(80px);
            left: 0;
            padding-left: px2rem(70px);
        }
        .big {
            font-size: 50px;
            font-weight: bold;
            margin-bottom: 10px;
            @media screen and (max-width: 1024px) {
                font-size: px2rem(55px);
                line-height: 1.1;
                margin-bottom: px2rem(5px);
            }
        }
        .small {
            font-size: 35px;
            line-height: 40px;
            font-weight: bold;
            @media screen and (max-width: 1024px) {
                font-size: px2rem(32px);
                line-height: 1.5;
                font-weight: bold;
            }
        }
    }
}
.partner-section {
    padding: px2rem(90px) 0 px2rem(100px);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}
.partner-img-list4 {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -6px;
    font-size: 0;
    padding-top: px2rem(40px);
    li {
        display: inline-block;
        vertical-align: top;
        width: 25%;
        padding: 0 6px;
        box-sizing: border-box;
        margin-bottom: 12px;
    }
    .wrapper {
        overflow: hidden;
        background: #f5f8fa;
        border: 1px solid #dfeaf4;
        border-radius: 10px;
        height: 100%;
        transition: all .2s;
        &:hover {
            transform: translateY(-10px);
        }
    }
    .img {
        position: relative;
        overflow: hidden;
        border-radius: 10px 10px 0 0;
        padding-top: percentage(180/290);
        img {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            object-fit: cover;
        }
    }
    .info {
        text-align: center;
        padding: px2rem(35px) px2rem(30px);
    }
    .t {
        position: relative;
        font-size: 24px;
        color: #000;
        padding-bottom: 15px;
        &:before {
            position: absolute;
            left: 50%;
            bottom: 0;
            content: '';
            width: 30px;
            height: 4px;
            background: #138aff;
            border-radius: 2px;
            margin-left: -15px;
        }
    }
    .p {
        font-size: 16px;
        line-height: 30px;
        color: #333;
        margin-top: 10px;
        text-align: left;
    }
    @media (max-width: 800px) {
        li {
            width: 50%;
        }
    }
    @media (max-width: 750px) {
        .t {
            font-size: 16px;
        }
        .p {
            font-size: 14px;
            line-height: 26px;
        }
    }
}
.partner-icon-list {
    font-size: 0;
    margin: 0 -7px;
    padding-top: px2rem(40px);
    li {
        box-sizing: border-box;
        display: inline-block;
        vertical-align: top;
        padding: 0 7px;
        margin-bottom: 14px;
        width: 33.33%;
    }
    .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 310px;
        background-color: #fff;
        border: 1px solid #dfeaf4;
        border-radius: 10px;
        overflow: hidden;
        &:hover {
            .icon {
                transform: translateY(-10px);
            }
        }
    }
    .icon {
        width: px2rem(180px);
        height: px2rem(140px);
        margin-bottom: 10px;
        transition: all .4s;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .t {
        text-align: center;
        font-size: 24px;
        font-weight: bold;
        color: #333;
        margin-bottom: 10px;
    }
    .p {
        width: 220px;
        max-width: 100%;
        text-align: center;
        font-size: 18px;
        line-height: 35px;
        color: #333;
        margin-bottom: 10px;
    }
    @media (max-width: 750px) {
        li {
            width: 50%;
        }
        .wrapper {
            height: auto;
            padding: px2rem(20px);
        }
        .t {
            font-size: 16px;
        }
        .p {
            font-size: 14px;
            line-height: 26px;
            height: 26px * 3;
            overflow: hidden;
        }
    }
}
.partner-img-list3 {
    padding-top: px2rem(40px);
    font-size: 0;
    margin: 0 -7px;
    li {
        display: inline-block;
        vertical-align: top;
        width: 33.33%;
        box-sizing: border-box;
        padding: 0 7px;
        margin-bottom: 14px;
    }
    .wrapper {
        background-color: #f5f8fa;
        border: 1px solid #dfeaf4;
        border-radius: 10px;
        overflow: hidden;
        &:hover {
            .img img {
                transform: scale(1.05);
            }
        }
    }
    .img {
        position: relative;
        width: 100%;
        padding-top: percentage(230/390);
        overflow: hidden;
        border-radius: 10px 10px 0 0;
        img {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            object-fit: cover;
            transition: all .4s;
        }
    }
    .info {
        font-size: 18px;
        line-height: 30px;
        height: 60px;
        text-align: center;
        padding: px2rem(20px);
        overflow: hidden;
        color: #333;
    }
    @media (max-width: 800px) {
        .info {
            font-size: 14px;
        }
    }
    @media (max-width: 750px) {
        li {
            width: 100%;
        }
    }
}
.partner-img {
    margin-top: px2rem(40px);
    text-align: center;
    img {
        max-width: 100%;
    }
}