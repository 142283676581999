.share-box {
    display: flex;
    justify-content: center;
    margin-top: px2rem(20px);
    align-items: center;
    &-item {
        cursor: pointer;
        background-color: #181818;
        color: #fff;
        margin-right: 15px;
        width: 40px;
        height: 40px;
        text-align: center;
        border-radius: 50%;
        .iconfont {
            font-size: 20px;
            line-height: 40px;
        }
    }
    .share-to-wechat {
        position: relative;
        .wechat-ewm {
            position: absolute;
            border-radius: 5px;
            border: 1px solid #eee;
            background: #fff;
            left: 50%;
            top: 32px;
            padding: 10px 10px 5px;
            display: none;
            transform: translateX(-50%);
            p {
                font-size: 12px;
                color: #333;
                text-align: center;
                margin-top: 5px;
            }
        }
        &:hover {
            background-color: #3dbe5b;
            .wechat-ewm {
                display: block;
            }
        }
    }
    .share-to-qq,
    .share-to-qzone,
    .share-to-weibo,
    .share-to-wechat {
        transition: background-color .3s;
    }
    .share-to-qq:hover {
        background-color: #21b3f7;
    }
    .share-to-qzone:hover {
        background-color: #f8c514;
    }
    .share-to-weibo:hover {
        background-color: #ff5757;
    }
}
.wechat-tip-mask {
    top: 0;
    left: 0;
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: none;
    background-image: url(../images/share_bg.png);
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100% auto;
}


