.product-banner {
    position: relative;
    overflow: hidden;
    .bg {
        height: 580px;
        @media screen and (max-width: 1024px) {
            height: px2rem(630px);
        }
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            &:nth-of-type(2) {
                display: none;
            }
            @media screen and (max-width: 1024px) {
                &:nth-of-type(1) {
                    display: none;
                }
                &:nth-of-type(2) {
                    display: inline-block;
                }
            }
        }
    }
    .word {
        position: absolute;
        backface-visibility: hidden;
        left: 50%;
        margin-left: -600px;
        top: 140px;
        z-index: 2;
        width: 600px;
        color: #333;
        font-family: Source Han Sans CN;
        @media screen and (max-width: 1260px) {
            margin-left: 0;
            width: 100%;
            top: px2rem(80px);
            left: 0;
            padding-left: px2rem(70px);
        }
        .big {
            font-size: px2rem(65px);
            font-weight: bold;
            margin-bottom: 25px;
            @media screen and (max-width: 1024px) {
                margin-bottom: 0;
            }
        }
        .bigs {
            font-size: 50px;
            font-weight: bold;
            margin-bottom: 10px;
            @media screen and (max-width: 1024px) {
                font-size: px2rem(55px);
                line-height: 1.1;
                margin-bottom: px2rem(5px);
            }
        }
        .small {
            font-size: 24px;
            line-height: 40px;
            @media screen and (max-width: 1024px) {
                font-size: px2rem(32px);
                line-height: 1.5;
                font-weight: bold;
            }
        }
        .smalls {
            font-size: 35px;
            line-height: 40px;
            font-weight: bold;
            @media screen and (max-width: 1024px) {
                font-size: px2rem(32px);
                line-height: 1.5;
            }
        }
        .gradient {
            background: linear-gradient(160deg, #4b4bef, #017fff 50%, #0c98ff 75%, #25c9ec);
            background-clip: text;
            color: transparent;
        }
    }
    // @media (max-width: 750px) {
    //     display: none;
    // }
}
.product-section {
    background-color: #fff;
    background-position: center top;
    background-repeat: no-repeat;
    background-size: cover;
    padding: px2rem(50px) 0 px2rem(30px);
    overflow: hidden;
    &:nth-child(2n) {
        background-image: url(../images/product_bg.jpg);
        .box {
            flex-direction: row-reverse;
        }
    }
    &:nth-child(2n+1) {
        background-image: url(../images/product_bg2.jpg);
    }
    &:first-child {
        background-image: none;
    }
    .wrapper {
        width: 1330px;
        margin: 0 auto;
        @media (max-width: 1390px) {
            width: auto;
            margin: 0 px2rem(30px);
        }
    }
    .top {
        position: relative;
        text-align: center;
        font-size: 50px;
        color: #333;
        line-height: 65px;
        padding-bottom: px2rem(30px);
        margin-bottom: px2rem(50px);
        &:after {
            position: absolute;
            left: 50%;
            bottom: 0;
            width: 60px;
            height: 8px;
            border-radius: 4px;
            margin-left: -30px;
            background: #1f78fd;
            content: '';
        }
    }
    .box {
        display: flex;
        justify-content: space-between;
        .img {
            width: 670px;
            height: 600px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
        .info {
            flex: 1;
            overflow: hidden;
            max-width: 540px;
            padding-top: px2rem(110px);
        }
        .title {
            font-size: 38px;
            line-height: 55px;
            font-weight: bold;
            color: #333;
            margin-bottom: px2rem(20px);
        }
        .p {
            font-size: 22px;
            line-height: 40px;
            color: #777;
        }
    }
    @media (max-width: 1000px) {
        .box {
            display: block;
            .info {
                max-width: 100%;
            }
        }
    }
    @media (max-width: 750px) {
        .top {
            font-size: 20px;
            line-height: 32px;
            &:after {
                height: 4px;
            }
        }
        .box {
            .info {
                padding-top: px2rem(60px);
            }
            .title {
                font-size: 18px;
                line-height: 30px;
            }
            .p {
                font-size: 14px;
                line-height: 26px;
            }
            .img {
                width: 100%;
                height: px2rem(600px);
            }
        }
    }
}