.open-banner {
    position: relative;
    overflow: hidden;
    .bg {
        height: 580px;
        @media screen and (max-width: 1024px) {
            height: px2rem(630px);
        }
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            &:nth-of-type(2) {
                display: none;
            }
            @media screen and (max-width: 1024px) {
                &:nth-of-type(1) {
                    display: none;
                }
                &:nth-of-type(2) {
                    display: inline-block;
                }
            }
        }
    }
    .word {
        position: absolute;
        backface-visibility: hidden;
        left: 50%;
        margin-left: -600px;
        top: 140px;
        z-index: 2;
        width: 560px;
        font-size: 45px;
        font-weight: bold;
        color: #333;
        @media screen and (max-width: 1260px) {
            margin-left: 0;
            width: 100%;
            top: px2rem(80px);
            left: 0;
            padding-left: px2rem(70px);
            font-size: px2rem(50px);
        }
        p {
            font-weight: bold;
        }
    }
    // @media (max-width: 750px) {
    //     display: none;
    // }
}
.open-section {
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center top;
    &.pt-90 {
        padding-top: px2rem(90px);
    }
    &.pb-90 {
        padding-bottom: px2rem(90px);
    }
    &.pt-60 {
        padding-top: px2rem(60px);
    }
    &.bgs-x {
        background-size: 100% auto;
    }
    &.bgs-cv {
        background-size: cover;
    }
}
.open-quote {
    position: relative;
    color: #333;
    padding: 60px 150px 0 0;
    margin-bottom: px2rem(40px);
    &:before {
        position: absolute;
        left: 0;
        top: 0;
        content: '';
        width: 30px;
        height: 24px;
        background-image: url(../images/open_icon1.png);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100% 100%;
    }
    &:after {
        position: absolute;
        right: 0;
        bottom: 0;
        content: '';
        width: 129px;
        height: 24px;
        background-image: url(../images/open_icon2.png);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100% 100%;
    }
    .big {
        font-size: 45px;
        font-weight: bold;
        margin-bottom: px2rem(20px);
    }
    .p {
        font-size: 24px;
        line-height: 40px;
    }
    @media (max-width: 750px) {
        padding: 40px 0;
        .big {
            font-size: 20px;
        }
        .p {
            font-size: 16px;
            line-height: 30px;
        }
    }
}
.open-title {
    text-align: center;
    position: relative;
    color: #333;
    padding-bottom: 32px;
    font-size: 40px;
    font-weight: bold;
    &:after {
        position: absolute;
        left: 50%;
        bottom: 0;
        width: 50px;
        height: 5px;
        border-radius: 3px;
        content: '';
        margin-left: -25px;
        background: #138aff;
    }
    @media (max-width: 750px) {
        font-size: 20px;
        padding-bottom: 16px;
        &:after {
            height: 3px;
        }
    }
}
.open-feat {
    border-top: 1px dashed #d8d8d8;
    padding-top: px2rem(60px);
    &-list {
        margin: px2rem(35px) -7px 0;
        font-size: 0;
        display: flex;
        flex-wrap: wrap;
        li {
            width: 33.33%;
            box-sizing: border-box;
            padding: 0 7px;
            margin-bottom: 14px;
        }
        .wrapper {
            display: block;
            background-color: #f5f8fa;
            border: 1px solid #dfeaf4;
            border-radius: 10px;
            overflow: hidden;
            height: 100%;
        }
        .img {
            width: 100%;
            position: relative;
            padding-top: percentage(240/388);
            overflow: hidden;
            img {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                object-fit: cover;
            }
        }
        .t {
            text-align: center;
            font-size: 24px;
            line-height: 60px;
            font-weight: bold;
            color: #333;
            padding: 0 px2rem(20px);
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-bottom: 10px;
        }
        .p {
            width: 260px;
            margin: 0 auto;
            max-width: 100%;
            font-size: 18px;
            line-height: 35px;
            height: 35px * 3;
            margin-bottom: px2rem(40px);
            @include multiEllipsis(3);
        }
        @media (max-width: 750px) {
            li {
                width: 100%;
            }
            .t {
                font-size: 18px;
                line-height: 40px;
            }
            .p {
                font-size: 16px;
                line-height: 30px;
                height: 90px;
            }
        }
    }
}
.open-api-list {
    overflow: hidden;
    li {
        display: flex;
        padding: px2rem(60px) 0;
        border-bottom: 1px solid #eee;
        justify-content: space-between;
        &:last-child {
            border-bottom: none;
        }
        &:nth-child(2n),
        &.reverse {
            flex-direction: row-reverse;
        }
        .img {
            width: 400px;
            max-width: 100%;
            margin-top: px2rem(60px);
            img {
                width: 100%;
            }
        }
        .info {
            width: 580px;
        }
        .t {
            font-size: 38px;
            font-weight: bold;
            color: #333;
            margin-bottom: px2rem(20px);
        }
        .p {
            font-size: 22px;
            line-height: 40px;
            color: #777;
            margin-bottom: px2rem(20px);
            width: 500px;
            max-width: 100%;
        }
        .tag-box {
            font-size: 0;
            .tag {
                display: inline-block;
                vertical-align: top;
                margin: 0 10px 10px 0;
                min-width: 152px;
                height: 42px;
                box-sizing: border-box;
                border: 1px solid #dfeaf4;
                border-radius: 21px;
                background: #f5f8fa;
                padding: 0 px2rem(20px);
                font-size: 20px;
                line-height: 42px;
                color: #708baa;
                text-align: center;
            }
        }
        .list {
            border-top: 1px dashed #e8e8e8;
            margin-top: px2rem(30px);
            padding-top: px2rem(40px);
            .li {
                display: flex;
                margin-bottom: px2rem(20px);
                align-items: center;
            }
            .w {
                min-width: 100px;
                height: 40px;
                border-radius: 20px;
                box-sizing: border-box;
                line-height: 40px;
                padding: 0 px2rem(20px);
                text-align: center;
                font-size: 18px;
                font-weight: bold;
                color: #333;
                background: #ebf0f7;
                margin-right: 10px;
            }
            .des {
                flex: 1;
                overflow: hidden;
                font-size: 16px;
                font-weight: bold;
                color: #777;
            }
        }
    }
    @media (max-width: 800px) {
        li {
            display: block;
            .info {
                width: 100%;
            }
            .p {
                width: 100%;
            }
            .img {
                margin: px2rem(40px) auto 0;
            }
        }
    }
    @media (max-width: 750px) {
        li {
            .t {
                font-size: 18px;
            }
            .p {
                font-size: 16px;
                line-height: 30px;
            }
            .tag-box {
                .tag {
                    margin: 0 5px 5px 0;
                    height: 32px;
                    line-height: 32px;
                    font-size: 12px;
                    min-width: 0;
                }
            }
            .list {
                .w {
                    min-width: 80px;
                    height: 32px;
                    line-height: 32px;
                    font-size: 14px;
                }
                .des {
                    font-size: 14px;
                }
            }
        }
    }
}
.open-service {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    .wp1200 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: px2rem(280px);
    }
    .word {
        flex: 1;
        overflow: hidden;
        color: #fff;
        font-weight: bold;
        .big {
            font-size: px2rem(50px);
        }
        .small {
            font-size: px2rem(32px);
        }
    }
    .service-btn {
        display: flex;
        height: 70px;
        width: 220px;
        border-radius: 35px;
        text-align: center;
        justify-content: center;
        align-items: center;
        color: #fff;
        transition: all .2s;
        font-size: 18px;
        background: #1f78fd;
        overflow: hidden;
        i {
            transform: rotate(-90deg);
            margin-left: px2rem(10px);
            margin-top: 2px;
            transition: all .2s;
        }
        &:hover {
            background: lighten(#1f78fd, 5%);
            i {
                margin-left: px2rem(15px);
            }
        }
    }
}