.about-banner {
    position: relative;
    overflow: hidden;
    .bg {
        height: 580px;
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .word {
        position: absolute;
        backface-visibility: hidden;
        left: 50%;
        margin-left: -640px;
        top: 140px;
        z-index: 2;
        width: 590px;
        font-size: 45px;
        font-weight: bold;
        color: #333;
        p {
            font-weight: bold;
        }
    }
    @media (max-width: 1300px) {
        .word {
            left: px2rem(30px);
            margin-left: 0;
        }
    }
    @media (max-width: 750px) {
        display: none;
    }
}
.about-section {
    background-color: #fff;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    &.pt-90 {
        padding-top: px2rem(90px);
    }
    &.pb-90 {
        padding-bottom: px2rem(90px);
    }
}
.about-title {
    text-align: center;
    position: relative;
    font-size: 40px;
    color: #333;
    font-weight: bold;
    padding-bottom: 30px;
    &:after {
        position: absolute;
        left: 50%;
        bottom: 0;
        content: '';
        width: 50px;
        height: 6px;
        border-radius: 3px;
        margin-left: -25px;
        background: #1f78fd;
    }
    .p {
        font-size: 20px;
        line-height: 30px;
        font-weight: normal;
        margin-top: 5px;
    }
    @media (max-width: 750px) {
        font-size: 18px;
        padding-bottom: 20px;
        .p {
            font-size: 14px;
        }
        &:after {
            height: 3px;
        }
    }
}
.about-article {
    margin-top: px2rem(30px);
    text-align: center;
    font-size: 20px;
    line-height: 40px;
    color: #333;
    @media (max-width: 750px) {
        font-size: 14px;
        line-height: 28px;
    }
}
.about-intro-box {
    height: 640px;
    margin-top: px2rem(40px);
    position: relative;
    overflow: hidden;
    .pc-bg,
    .mobile-bg {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
    }
    .mobile-bg {
        display: none;
    }
    .box {
        width: 680px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 640px;
        top: 0;
        left: 50%;
        margin-left: -80px;
        position: absolute;
        z-index: 2;
    }
    .article {
        font-size: 18px;
        line-height: 40px;
        color: #333;
        p {
            margin-bottom: 20px;
        }
    }
    .num-box {
        display: flex;
        padding-top: px2rem(40px);
        border-top: 1px dashed #d3ddde;
        justify-content: space-around;
        align-items: center;
        .item {
            position: relative;
            color: #339bf3;
            + .item {
                padding-left: px2rem(60px);
                &:before {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    content: '';
                    width: 1px;
                    height: 40px;
                    margin-top: -20px;
                    background: #d9e0e4;
                }
            }
            .num {
                font-size: 30px;
                font-weight: bold;
                line-height: 40px;
            }
            .p {
                font-size: 18px;
            }
            .n {
                display: inline-block;
                vertical-align: top;
            }
        }
    }
    @media (max-width: 1024px) {
        .box {
            width: auto;
            right: px2rem(30px);
        }
    }
    @media (max-width: 800px) {
        height: auto;
        //padding: px2rem(30px) px2rem(30px) px2rem(60px);
        .pc-bg {
            display: none;
        }
        .mobile-bg {
            display: block;
            width: 100%;
            height: 0;
            padding-top: percentage(600/750);
            position: static;
        }
        .box {
            padding: 0 px2rem(30px) px2rem(60px);
            position: relative;
            right: auto;
            display: block;
            left: auto;
            height: auto;
            margin-left: 0;
            background: linear-gradient(to bottom, #f9fefb, #e7f9fe);
        }
    }
    @media (max-width: 750px) {
        .article {
            font-size: 16px;
            line-height: 30px;
            p {
                margin-bottom: 15px;
            }
        }
        .num-box {
            .item {
                text-align: center;
                + .item {
                    padding-left: px2rem(20px);
                }
                .num {
                    font-size: 18px;
                    line-height: 32px;
                }
                .p {
                    font-size: 14px;
                }
            }
        }
    }
}
.about-card-box {
    margin-top: px2rem(50px);
    display: flex;
    .card-item {
        flex: 1;
        display: flex;
        margin-bottom: px2rem(20px);
        border: 1px solid #dfeaf4;
        border-radius: px2rem(10px);
        overflow: hidden;
        background-color: #f4f8fb;
        align-items: center;
        + .card-item {
            margin-left: px2rem(20px);
        }
        .info {
            flex: 1;
            min-width: 0;
            margin-left: px2rem(40px);
            margin-right: px2rem(20px);
        }
        .p {
            font-size: 24px;
            line-height: 35px;
            color: #666;
        }
        .card-title {
            margin-bottom: 10px;
        }
        .img {
            width: 260px;
            height: 250px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
    }
    @media (max-width: 800px) {
        display: block;
        .card-item {
            + .card-item {
                margin-left: 0;
            }
        }
    }
    @media (max-width: 750px) {
        .card-item {
            display: block;
            padding: px2rem(40px) px2rem(30px) 0;
            .info {
                margin-left: 0;
                margin-right: 0;
            }
            .p {
                font-size: 16px;
                line-height: 28px;
            }
            .img {
                margin: px2rem(20px) auto 0;
            }
        }
    }
}
.card-title {
    position: relative;
    .cn {
        font-size: 28px;
        position: relative;
        z-index: 2;
        color: #000;
    }
    .en {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 1;
        font-family: Arial;
        font-size: 50px;
        font-weight: bold;
        text-transform: uppercase;
        opacity: .1;
        color: #339bf3;
        line-height: 50px;
    }
    @media (max-width: 750px) {
        .cn {
            font-size: 22px;
        }
        .en {
            font-size: 36px;
            line-height: 36px;
        }
    }
}
.about-card-long-box {
    border: 1px solid #dfeaf4;
    border-radius: px2rem(10px);
    overflow: hidden;
    background-color: #f4f8fb;
    padding: px2rem(40px) px2rem(50px) px2rem(30px);
    .card-icon-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .item {
            text-align: center;
            font-size: 24px;
            color: #666;
            margin-top: 10px;
            img {
                width: 160px;
                height: 160px;
                display: block;
                margin: 0 auto;
            }
        }
    }
    @media (max-width: 800px) {
        .card-icon-box {
            .item {
                flex: 1;
                overflow: hidden;
                box-sizing: border-box;
                padding: 0 px2rem(10px);
                .img {
                    width: 100%;
                    padding-top: 100%;
                    position: relative;
                    overflow: hidden;
                    img {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        object-fit: cover;
                    }
                }
            }
        }
    }
    @media (max-width: 750px) {
        padding-right: px2rem(30px);
        padding-left: px2rem(30px);
        padding-bottom: px2rem(60px);
        .card-icon-box {
            display: block;
            font-size: 0;
            .item {
                display: inline-block;
                vertical-align: top;
                width: 50%;
                font-size: 20px;
            }
        }
    }
}
.about-investor-swiper {
    margin-top: px2rem(40px);
    .swiper-slide {
        border-radius: 8px;
        overflow: hidden;
        width: 200px;
        transition: all .4s;
        cursor: pointer;
        .img {
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            position: relative;
            height: 415px;
            overflow: hidden;
            border-radius: 8px;
            &:after {
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background-image: url(../images/about_bg3.png);
                background-position: center center;
                background-repeat: repeat-x;
                background-size: 1px 100%;
                content: '';
                z-index: 1;
            }
            .name {
                position: absolute;
                text-align: center;
                z-index: 2;
                left: 0;
                width: 100%;
                bottom: px2rem(20px);
                color: #fff;
                font-size: 22px;
                font-weight: bold;
            }
        }
        .intro {
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: 4;
            background-color: #fff;
            box-sizing: border-box;
            width: 100%;
            border-radius: 8px 8px 0 0;
            padding: px2rem(30px) px2rem(20px) px2rem(15px);
            transition: all .4s;
            opacity: 0;
            transform: translateY(100%);
            .name {
                font-size: 26px;
                font-weight: bold;
                color: #333;
                margin-bottom: 10px;
            }
            .p {
                font-size: 16px;
                line-height: 25px;
                color: #333;
            }
            .li {
                position: relative;
                padding-left: 15px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                margin-bottom: 10px;
                &:before {
                    position: absolute;
                    content: '';
                    border-radius: 100%;
                    background: #1f78fd;
                    width: 6px;
                    height: 6px;
                    left: 0;
                    top: 9px;
                }
            }
        }
        &.on {
            width: 360px;
            .intro {
                opacity: 1;
                transform: translateY(0);
            }
        }
    }
    .swiper-pagination {
        position: static;
        padding-top: px2rem(30px);
        &-bullet {
            opacity: 1;
            border: 2px solid #1f78fd;
            background: none;
            outline: none;
            &-active {
                background: #1f78fd;
            }
        }
    }
    @media (max-width: 750px) {
        .swiper-slide {
            .intro {
                opacity: 1;
                transform: translateY(0);
            }
        }
    }
}
.about-member {
    &-con {
        margin-top: px2rem(50px);
    }
    &-title {
        font-size: 26px;
        font-weight: bold;
        position: relative;
        padding-left: 24px;
        line-height: 30px;
        &:before {
            position: absolute;
            left: 0;
            top: 1px;
            width: 12px;
            height: 28px;
            background: #1f78fd;
            content: '';
            border-radius: 6px;
        }
    }
    &-item {
        margin-top: px2rem(30px);
        border: 1px solid #e0f4fd;
        background-color: #fff;
        border-radius: 10px;
        overflow: hidden;
        display: flex;
        .img {
            width: 320px;
            span {
                display: block;
                position: relative;
                width: 320px;
                height: 370px;
                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
        }
        .box {
            flex: 1;
            overflow: hidden;
            display: flex;
            align-items: center;
            //margin: 0 px2rem(40px) 0 px2rem(30px);
            background-image: url(../images/about_bg4.png);
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
        }
        .info {
            flex: 1;
            margin-left: px2rem(30px);
            min-width: 0;
        }
        .top {
            font-size: 18px;
            color: #666;
            margin-bottom: px2rem(15px);
            strong {
                font-size: 28px;
                color: #333;
                margin-right: 4px;
            }
        }
        .ewm {
            margin-right: px2rem(40px);
            text-align: center;
            font-size: 16px;
            color: #b6c6d9;
            img {
                width: 240px;
                height: 240px;
                display: block;
                margin: 0 auto;
            }
        }
        .phone {
            font-size: 20px;
            line-height: 30px;
            i {
                display: inline-block;
                vertical-align: top;
                line-height: 30px;
                color: #2b63f8;
            }
            a {
                color: #333;
            }
        }
        .p {
            background-color: #e6f4ff;
            border: 1px solid #d3e7f3;
            width: 380px;
            padding: 15px 20px;
            border-radius: 10px;
            font-size: 16px;
            line-height: 25px;
            min-height: 50px;
            position: relative;
            margin-top: 25px;
            max-width: 100%;
            &:before {
                position: absolute;
                background-image: url(../images/arrow2.png);
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;
                width: 20px;
                height: 10px;
                top: -9px;
                left: 24px;
                content: '';
            }
        }
        .impress {
            margin-top: px2rem(30px);
            font-size: 16px;
            color: #333;
            .tag-box {
                font-size: 0;
            }
            .tag {
                display: inline-block;
                vertical-align: top;
                box-sizing: border-box;
                min-width: 100px;
                border: 1px solid #bfd5f0;
                background: #e9f3ff;
                text-align: center;
                padding: 0 20px;
                height: 32px;
                line-height: 32px;
                border-radius: 16px;
                font-size: 14px;
                color: #7393ba;
                margin: 10px 10px 0 0;
            }
        }
    }
    @media (max-width: 1024px) {
        &-item {
            .img {
                width: 300px;
                span {
                    width: 100%;
                    height: 0;
                    padding-top: percentage(370/320);
                }
            }
            .ewm {
                display: none;
            }
            .info {
                margin-right: px2rem(30px);
            }
            .p {
                box-sizing: border-box;
            }
        }
    }
    @media (max-width: 750px) {
        &-title {
            font-size: 16px;
            padding-left: 12px;
            &:before {
                height: 20px;
                top: 5px;
                width: 6px;
            }
        }
        &-item {
            .img {
                width: 140px;
                span {
                    height: 300px;
                    padding-top: 0;
                }
            }
            .top {
                font-size: 14px;
                strong {
                    font-size: 20px;
                }
            }
            .phone {
                font-size: 16px;
            }
            .p {
                margin-top: 10px;
                padding: 10px;
                font-size: 12px;
                line-height: 20px;
            }
            .impress {
                font-size: 14px;
                .tag {
                    min-width: 0;
                    padding: 0 5px;
                    height: 28px;
                    line-height: 28px;
                    font-size: 10px;
                    margin: 5px 5px 0 0;
                }
            }
        }
    }
}