.case-banner {
    position: relative;
    overflow: hidden;
    .bg {
        height: 420px;
        @media screen and (max-width: 1024px) {
            height: px2rem(630px);
        }
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            &:nth-of-type(2) {
                display: none;
            }
            @media screen and (max-width: 1024px) {
                &:nth-of-type(1) {
                    display: none;
                }
                &:nth-of-type(2) {
                    display: inline-block;
                }
            }
        }
    }
    .word {
        position: absolute;
        backface-visibility: hidden;
        left: 50%;
        margin-left: -600px;
        top: 140px;
        z-index: 2;
        width: 530px;
        @media screen and (max-width: 1260px) {
            margin-left: 0;
            width: 100%;
            top: px2rem(80px);
            left: 0;
            padding-left: px2rem(70px);
        }
        .big {
            font-size: 55px;
            font-weight: bold;
            @media screen and (max-width: 1024px) {
                font-size: px2rem(55px);
                line-height: 1.1;
                margin-bottom: px2rem(5px);
            }
        }
        .small {
            font-size: 32px;
            @media screen and (max-width: 1024px) {
                font-size: px2rem(32px);
                line-height: 1.5;
                font-weight: bold;
            }
        }
    }
}
.case-list-con {
    background-color: #fff;
    padding-bottom: px2rem(60px);
}
.case-list {
    font-size: 0;
    overflow: hidden;
    li {
        display: inline-block;
        vertical-align: top;
        width: 50%;
        box-sizing: border-box;
        margin-top: px2rem(50px);
        &:nth-child(2n+1) {
            padding-right: px2rem(19px);
        }
        &:nth-child(2n) {
            padding-left: px2rem(19px);
        }
    }
    .wrapper {
        display: block;
        overflow: hidden;
        border-radius: 8px;
        background-color: #f7fafc;
        border: 1px solid #e0e7f2;
        transition: all .4s;
        &:hover {
            box-shadow: 0 0 20px rgba(#000, .1);
            transform: translateY(-10px);
            .img img {
                transform: scale(1.05);
            }
        }
    }
    .img {
        position: relative;
        width: 100%;
        padding-top: percentage(334/580);
        overflow: hidden;
        img {
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            transition: all .5s;
            object-fit: cover;
        }
    }
    .info {
        padding: px2rem(38px) px2rem(38px) px2rem(28px);
    }
    .title {
        font-size: 32px;
        font-weight: bold;
        color: #000;
        line-height: 44px;
        height: 44px;
        overflow: hidden;
        margin-bottom: 10px;
    }
    .p {
        font-size: 20px;
        line-height: 35px;
        height: 70px;
        overflow: hidden;
        color: #000;
    }
    .bottom {
        margin-top: px2rem(15px);
        border-top: 1px dashed #d6d9da;
        padding-top: px2rem(26px);
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 40px;
        .btn {
            width: 140px;
            height: 40px;
            line-height: 40px;
            border-radius: 20px;
            text-align: center;
            color: #fff;
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #1f78fd;
            img {
                height: 100%;
                margin-left: px2rem(20px);
            }
        }
        .logo {
            img {
                height: 40px;
                width: auto;
            }
        }
    }
    @media (max-width: 750px) {
        li {
            width: 100%;
            padding: 0 !important;
        }
        .title {
            font-size: 22px;
            line-height: 32px;
            height: 32px;
        }
        .p {
            font-size: 16px;
            line-height: 30px;
            height: 60px;
        }
        .bottom {
            .btn {
                width: 100px;
                height: 32px;
                line-height: 32px;
                font-size: 12px;
            }
        }
    }
}
.case-partner {
    padding: px2rem(90px) 0 px2rem(100px);
    background-image: url(../images/case_bg.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    .title {
        margin-bottom: px2rem(40px);
        text-align: center;
        position: relative;
        padding-bottom: px2rem(30px);
        font-size: 40px;
        font-weight: bold;
        color: #333;
        &:after {
            position: absolute;
            left: 50%;
            bottom: 0;
            content: '';
            width: 50px;
            height: 6px;
            border-radius: 3px;
            background: #2c64fb;
            margin-left: -25px;
        }
    }
    &-swiper {
        .img {
            border: 1px solid #dde6f0;
            position: relative;
            overflow: hidden;
            background-color: #fff;
            padding-top: percentage(98/230);
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        .swiper-pagination {
            position: static;
            margin-top: px2rem(40px);
            &-bullet {
                border: 2px solid #1f78fd;
                opacity: 1;
                background: none;
                &-active {
                    background: #1f78fd;
                }
            }
        }
    }
    @media (max-width: 750px) {
        .title {
            font-size: 18px;
            &:after {
                height: 3px;
            }
        }
    }
}
.case-detail-con {
    background-color: #fff;
    padding: px2rem(70px) 0;
}
.case-detail-title {
    text-align: center;
    position: relative;
    font-size: px2rem(45px);
    font-weight: bold;
    color: #333;
    padding-bottom: px2rem(30px);
    margin-bottom: px2rem(30px);
    &:before {
        position: absolute;
        content: '';
        left: 50%;
        bottom: 0;
        background: #1f78fd;
        width: px2rem(50px);
        height: px2rem(6px);
        border-radius: px2rem(3px);
        margin-left: px2rem(-25px);
    }
}
.case-detail-article {
    line-height: 35px;
    color: #666;
    @include articleReset(16px, 35px);
}
.case-detail-table {
    display: flex;
    border: 1px solid #dfe6f2;
    border-radius: 8px;
    background: #fafdfd;
    .word {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: px2rem(45px);
        font-weight: bold;
        color: #333;
        line-height: 1.5;
        background-image: url(../images/case_bg1.jpg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        padding: 0 px2rem(50px);
        box-sizing: border-box;
        min-width: 230px;
    }
    .info {
        flex: 1;
        overflow: hidden;
        border-left: 1px solid #dfe6f2;
        .li {
            padding: px2rem(35px);
            border-bottom: 1px solid #dfe6f2;
            font-size: 16px;
            line-height: 35px;
            color: #333;
            &:last-child {
                border-bottom: none;
            }
        }
    }
    @media (max-width: 750px) {
        .word {
            min-width: 0;
        }
        .info {
            .li {
                font-size: 14px;
                line-height: 30px;
            }
        }
    }
}
.case-detail-subtitle {
    margin-top: px2rem(60px);
    margin-bottom: px2rem(30px);
    display: flex;
    align-items: center;
    justify-content: center;
    span {
        font-size: px2rem(35px);
        line-height: 40px;
        font-weight: bold;
        color: #333;
        padding: 0 px2rem(20px);
    }
    &:before,
    &:after {
        display: block;
        flex: 1;
        overflow: hidden;
        height: 18px;
        content: '';
        background-image: url(../images/case_bg2.png);
        background-repeat: no-repeat;
    }
    &:before {
        transform: rotate(180deg);
    }
    @media (max-width: 750px) {
        span {
            font-size: 20px;
        }
    }
}